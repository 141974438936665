<template>
  <table class="table table-responsive table-bordered border-0" id="preserviceBillsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">Processed</th>
        <th scope="col">Payment Log ID</th>
        <th scope="col">TaxPayer Name</th>
        <th scope="col">TaxPayer RIN</th>
        <th scope="col">Amount 	(&#8358;) </th>
        <th scope="col">Notes</th>
        <th scope="col">Service Bill Ref</th>
        <th scope="col">MDA Service Ref</th>
        <th scope="col">Settlement Date</th>
        <th scope="col">Tax Year</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="preServiceBill in tableData" :key="preServiceBill.id">
        <td><h6><span class="badge badge-secondary">{{preServiceBill.processed}}</span></h6></td>
        <td>{{preServiceBill.payment_log_id}}</td>
        <td>{{preServiceBill.taxpayer_name}}</td>
        <td>{{preServiceBill.taxpayer_rin}}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(preServiceBill.amount_paid) }}</td>
        <td>{{preServiceBill.notes}}</td>
        <td>{{preServiceBill.service_bill_ref}}</td>
        <td>{{preServiceBill.mda_service_ref}}</td>
        <td>{{preServiceBill.created_at | formatDate}}</td>
        <td>{{preServiceBill.tax_year | formatDate}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  }
}
</script>