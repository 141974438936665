<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Report by bank for {{month | formatMonth}}, {{year}} </h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="mb-3" @submit.prevent="updateBankDetailsReport()">
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group row justify-content-end">
                    <label class="col-sm-3 col-form-label">Month</label>
                    <div class="col-sm-9">
                      <v-select
                        :options="months"
                        label="label"
                        v-model="month"
                        :reduce="month => month.value"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group row justify-content-end">
                    <label class="col-sm-3 col-form-label">Bank</label>
                    <div class="col-sm-9">
                      <v-select
                        :options="bankLists"
                        label="bank_name"
                        v-model="newBankCode"
                        :reduce="bank => bank.code"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group row justify-content-end">
                    <label class="col-sm-3 col-form-label">Year</label>
                    <div class="col-sm-9">
                      <v-select
                        :options="years"
                        v-model="year"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <div class="row justify-content-end">
                    <div class="col-md-3">
                      <button class="btn btn--primary btn-block">Filter</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                  <div class="w-100">
                    <p class="text--secondary font-weight-bold mb-3">
                      Reports
                    </p>
                  </div>
                  <export-actions
                    :table-data="reports"
                    filename="Bank_Reports.xls"
                    :json-fields="jsonFields"
                    @generatePDF="generatePDF"
                  >
                    <button
                      class="ml-2 btn btn-sm btn-light" v-simple-print="'bankReportDetails'"
                    >Print</button>
                  </export-actions>
                </div>
                <bank-reports-details-table :table-data="reports" :bank-code='bankCode' />
              </div>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Bank Reports"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <bank-reports-details-table :table-data="reports" :bank-code='bankCode' />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import BankReportsDetailsTable from '@/components/tables/reportTables/BankReportDetailsTable'
import VueHtml2pdf from 'vue-html2pdf'
const monthsArr = [
  {
    label: 'January',
    value: '01'
  },
  {
    label: 'Feburary',
    value: '02'
  },
  {
    label: 'March',
    value: '03'
  },
  {
    label: 'April',
    value: '04'
  },
  {
    label: 'May',
    value: '05'
  },
  {
    label: 'June',
    value: '06'
  },
  {
    label: 'July',
    value: '07'
  },
  {
    label: 'August',
    value: '08'
  },
  {
    label: 'September',
    value: '09'
  },
  {
    label: 'October',
    value: '10'
  },
  {
    label: 'November',
    value: '11'
  },
  {
    label: 'December',
    value: '12'
  },
]
export default {
  props: ['bankCode'],
  components: {
    BankReportsDetailsTable,
     VueHtml2pdf
  },
   data() {
    return {
      months: monthsArr,
      reports: [],
      isLoadingPage: true,
      paginationData: {},
      revenueStream: [],
      bankLists: [],
      newBankCode: '',
      year: '',
      month: '',
      years: [],
      currentYear: new Date().getFullYear(),
    }
  },
  filters: {
    formatMonth: function (value) {
      if (!value) return ''
    return monthsArr.find(m => m.value === value).label
    },
  },
  methods: {
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getBankReports(showLoadingIndicator, fetchBanks) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        const qs = {bank_code: this.newBankCode, year: this.year, month: this.month}
        this.serializeQs(qs)
        const { data } = await this.$repos.reports.getBankDetailsCollection(qs);
        let startYear = Number(this.currentYear) - 20;
        let endYear = Number(this.currentYear)
        const yearsArray = []

        for (let i = startYear; i <= endYear; i++) {
          yearsArray.push((startYear++).toString())
        }
        this.years = [...new Set(yearsArray)]
        this.reports = [...data.reports]
        this.month = data.month
        this.year = data.year
        if(fetchBanks) {
          const { data: banks } = await this.$repos.reports.getBankList();
          Object.keys(banks).forEach(key => {
            this.bankLists.push(banks[key])
          })
        }
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    updateBankDetailsReport() {
      this.getBankReports (false, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        "Channel": "channel",
        "Channel Name": "channel_name",
        "Amount": "amount"
      }
    }
  },
  async mounted() {
    this.newBankCode = this.bankCode
    await this.getBankReports(true, true)
  },
}
</script>