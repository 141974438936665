<template>
  <table class="table table-responsive table-bordered border-0" id="reversedReportsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">S/N</th>
        <th scope="col">Date</th>
        <th scope="col">Payer Name</th>
        <th scope="col">Payment Log ID</th>
        <th scope="col">Original Payment Log ID</th>
        <th scope="col">Payment Reference</th>
        <th scope="col">Original Payment Reference</th>
        <th scope="col">Amount</th>
        <th scope="col">Reversal Date</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ new Date() | formatDate}}</td>
        <td>{{ data.depositor_name }}</td>
        <td>{{ data.payment_log_id }}</td>
        <td>{{ data.original_payment_log_id }}</td>
        <td>{{ data.payment_reference }}</td>
        <td>{{ data.original_payment_reference }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.amount) }}</td>
        <td>{{ data.created_at | formatDate }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  }
}
</script>