<template>
  <div>
    <table class="table table-responsive table-bordered border-0" id="cbdPayDirect">
      <caption class="d-none"></caption>
      <thead>
        <tr>
          <th scope="col">Processed</th>
          <th scope="col">Payment Log ID</th>
          <th scope="col">Customer Ref</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Amount 	&#8358; </th>
          <th scope="col">Bank</th>
          <th scope="col">Allocation Code</th>
          <th scope="col">Revenue Allocation</th>
          <th scope="col">Agency ID</th>
          <th scope="col">RIN</th>
          <th scope="col">Settlement Date</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="payDirect in tableData" :key="payDirect.id">
          <td><h6><span class="badge badge-secondary">{{payDirect.processed}}</span></h6></td>
          <td>{{payDirect.payment_log_id}}</td>
          <td>{{payDirect.customer_ref || 'N/A'}}</td>
          <td>{{payDirect.customer_name}}</td>
          <td>{{ new Intl.NumberFormat('en-GB').format(payDirect.amount) }}</td>
          <td>{{payDirect.bank || 'N/A'}}</td>
          <td>{{payDirect.allocation_code}}</td>
          <td>{{payDirect.revenue_allocation}}</td>
          <td>{{ payDirect.agency_id }}</td>
          <td>{{payDirect.rin}}</td>
          <td>{{payDirect.created_at | formatDate}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  }
}
</script>