<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Users</h1>
          <hr />
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
                <users-table :users="users" @updateUsers="getUsersAsync(queryStrings, false)" @showEditUserForm="showEditUserForm" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <EditUserModal>
      <EditUserForm ref="editUserForm" :roles="roles" />
    </EditUserModal>
  </div>
</template>

<script>
import UsersTable from '@/components/tables/userManagement/UsersTable'
import EditUserModal from '@/components/modals/users/EditUserModal'
import EditUserForm from '@/components/forms/users/user/EditUserForm'
export default {
  components: {
    UsersTable,
    EditUserModal,
    EditUserForm
  },
  data() {
    return {
      users: [],
      paginationData: {},
      user: {},
      isLoadingPage: true,
      queryStrings: {
        from_date: '1970-01-01',
        per_page: 30,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getUsersAsync(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.userManagementRepo.getUsers(qs);
        this.users = data
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    showEditUserForm(user) {
      console.log("🚀 ~ file: UsersList.vue ~ line 79 ~ showEditUserForm ~ user", user)
      this.user = {...user}
      this.$refs.editUserForm.seUserDetails(user)
      $('#editUserModal').modal({
        show: true,
        backdrop: 'static'
      })
    },
    async getRoles() {
      try {
        const { data: roles } = await this.$repos.userManagementRepo.getRoles();
        this.roles = roles
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    }
  },
  async mounted() {
    await this.getUsersAsync(this.queryStrings, true)
    await this.getRoles()
  },
}
</script>