<template>
  <div class="unprocessed-data">
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Unprocessed Data</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="image-box bg-purple mr-2">
                    <img src="../../assets/images/notification.svg" width="30" alt="">
                  </div>
                  <h4 class="title">Notifications</h4>
                </div>
                <h4 class="count">{{ unprocessedData.paydirect_notification }}</h4>
                <router-link tag="button" to="unprocessed-notifications" class="btn btn-light mt-2">View</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="image-box bg-green mr-2">
                    <img src="../../assets/images/wallet.svg" width="30" alt="">
                  </div>
                  <h4 class="title">CBS Paydirect</h4>
                </div>
                <h4 class="count">{{ unprocessedData.cbs_pay_direct }}</h4>
                <router-link tag="button" to="unprocessed-cbspaydirect" class="btn btn-light mt-2">View</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="image-box bg-orange mr-2">
                    <img src="../../assets/images/service-bills.svg" width="30" alt="">
                  </div>
                  <h4 class="title">Pre Service Bills</h4>
                </div>
                <h4 class="count">{{ unprocessedData.pre_service_bill }}</h4>
                <router-link tag="button" to="unprocessed-preservice-bills" class="btn btn-light mt-2">View</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="image-box bg-indigo mr-2">
                    <h4 class="title mb-0" style="color: #EA06E1; font-size: 18px">POA</h4>
                  </div>
                  <h4>POA</h4>
                </div>
                <h4 class="count">{{ unprocessedData.cbs_poa }}</h4>
                <router-link tag="button" to="unprocessed-poa" class="btn btn-light mt-2">View</router-link>
              </div>
            </div>
          </div>
        <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="image-box bg-yellow mr-2">
                    <img src="../../assets/images/checkmark.svg" width="30" alt="">
                  </div>
                  <h4 class="title">Settlements</h4>
                </div>
                <h4 class="count">{{ unprocessedData.settlement }}</h4>
                <router-link tag="button" to="unprocessed-settlements" class="btn btn-light mt-2">View</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center mb-3">
                  <div class="image-box bg-green mr-2">
                    <img src="../../assets/images/cbs-queue.svg" width="30" alt="">
                  </div>
                  <h4 class="title">CBS Queue</h4>
                </div>
                <h4 class="count">{{ unprocessedData.cbs_queue }}</h4>
                <router-link tag="button" to="unprocessed-cbs-queue" class="btn btn-light mt-2">View</router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      unprocessedData: {},
      isLoadingPage: true
    }
  },
  methods: {
    async getUnprocessedData() {
      try {
        const {data} = await this.$repos.unprocessed.getUnprocessedData()
        this.unprocessedData = {...data}
      }catch(error) {
        console.log(error);
      }finally {
        this.isLoadingPage = false
      }
    }
  },
  mounted() {
    this.getUnprocessedData()
  },
}
</script>

<style lang="scss" scoped>
  .unprocessed-data {
    .image-box {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      font-size: 20px;
    }
    .bg-purple {
      background: #EBE7F9;
    }
    .bg-green {
      background: #E7F9E7;
    }
    .bg-orange {
      background: #FFF1E0;
    }
    .bg-indigo {
      background: #FEE1FD;
    }
    .bg-yellow {
      background: #FFF8E1;
    }
  }
</style>