import axios from "axios";
import nprogress from "nprogress";
import 'nprogress/nprogress.css'


export const apiClient = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	headers: getHeaders()
});

apiClient.interceptors.request.use(config => {
	nprogress.start()
	config.headers = getHeaders()
	return config;
});

apiClient.interceptors.response.use(response => {
	nprogress.done()
	return response.data;
},function (error) {
	if(error.response.status === 401) {
		localStorage.clear()
		window.location = '/'
	}
  nprogress.done()
  return Promise.reject(error);
});

function getHeaders() {
	return {
	  Accept: "application/json",
	  Authorization: 'Bearer ' +
	  window.localStorage.getItem('accessToken')
	}
 }