<template>
  <table class="table table-sm-responsive table-bordered border-0" id="yearlyReportTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">S/N</th>
        <th scope="col">Year</th>
        <th scope="col">Pay On Account</th>
        <th scope="col">Manual Payment</th>
        <th scope="col">On Queue</th>
        <th scope="col">Bill Settlement</th>
        <th scope="col">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="data.year">
        <td>{{index + 1}}</td>
        <td>{{ data.year }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.poa) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.manual_poa) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.queue) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.settlement) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(data.poa) +  Number(data.manual_poa) + Number(data.settlement) + Number(data.queue)) }}</td>
      </tr>
      <tr>
        <td colspan="2"><span class="text--secondary font-weight-bold">Total</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.poa) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.manual_poa) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.queue) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.setlement) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(Number(totalReports.poa) +  Number(totalReports.manual_poa) + Number(totalReports.settlement) + Number(totalReports.queue)) }}</span></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
 props: {
   tableData: {
      type: Array,
      default: () => []
    },
    totalReports: {
      type: Object,
      default: () => {
        // intentionallly left empty
      }
    },
  }
}
</script>