<template>
  <table class="table table-responsive-md table-bordered border-0" id="revenueReportsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">S/N</th>
        <th scope="col">Revenue Stream</th>
        <th scope="col">Manual Payments</th>
        <th scope="col">Pay On Account (Total Matched)</th>
        <th scope="col">On Queue (Total Unmmatched)</th>
        <th scope="col">Settlement</th>
        <th scope="col">Total IGR</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ data.type }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.manual_poa) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.poa) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.queue) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.settlement) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(data.manual_poa) + Number(data.poa) + Number(data.queue) + Number(data.settlement)) }}</td>
      </tr>
      <tr>
        <td colspan="2"><span class="text--secondary font-weight-bold">Total</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalRevenueReport.manual_poa) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalRevenueReport.poa) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalRevenueReport.queue) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalRevenueReport.settlement) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(Number(totalRevenueReport.manual_poa) + Number(totalRevenueReport.poa) + Number(totalRevenueReport.queue) + Number(totalRevenueReport.settlement)) }}</span></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    totalRevenueReport: {
      type: Object,
      default: () => {
        //intentionally left empty
      }
    }
  }
}
</script>