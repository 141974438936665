<template>
  <table class="table table-responsive-sm table-bordered border-0" id="payDirectNotificationTable">
      <caption class="d-none"></caption>
      <thead>
        <tr>
          <th scope="col">Processed</th>
          <th scope="col">Payment Log ID</th>
          <th scope="col">Raw Request</th>
          <th scope="col">Raw response</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="notification in tableData" :key="notification.id">
          <td><h6><span class="badge badge-secondary">{{ notification.processed }}</span></h6></td>
          <td>{{ notification.payment_log_id }}</td>
          <td>
            <div :class="{'truncate-lines': notification.isTruncated}">{{ notification.raw_request }}</div>
            <div class="mt-3"><a class="btn-link pointer" @click="toggleStatus(notification)">{{notification.isTruncated ? 'View more' : 'View Less'}}</a></div>
          </td>
          <td>{{ notification.raw_response }}</td>
          <td><button class="btn btn--primary" @click="$router.push(`/admin/notification/${notification.id}/trace/`)">Trace</button></td>
        </tr>
      </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    toggleStatus(notification) {
      return notification.isTruncated = !notification.isTruncated
    }
  }
}
</script>

<style lang="scss">
  .truncate-lines {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>