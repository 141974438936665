<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-12 route-not-found-wrapper">
          <div class="not-found-img mb-4">
            <img src="@/assets/images/not-found.svg" height="100" alt="" />
          </div>
          <h1 class="fs-26">Sorry, this page isn't available</h1>
          <p class="text-muted text-center fs-20">
            The link you followed may be broken or the page may have been
            removed.
          </p>
          <div>
            <button @click="back" class="btn btn--primary">Back</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    back() {
      history.back();
    }
  }
};
</script>

<style lang="scss">
.route-not-found-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.route-not-found-link:hover {
  color: $primary;
  text-decoration: underline;
}
</style>
