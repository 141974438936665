<template>
  <form @submit.prevent="createUser">
    <!-- name -->
    <div class="form-group">
      <label for="surname">Name</label>
      <input
        type="text"
        v-model="user.name"
        class="form-control"
        id="surname"
        aria-describedby="surname"
      />
    </div>

    <!-- email -->
    <div class="form-group">
      <label for="othernames">Email</label>
      <input
        type="email"
        v-model="user.email"
        class="form-control"
        id="othernames"
        aria-describedby="othernames"
      />
    </div>

    <!-- password -->
    <div class="form-group">
      <label for="password">Password</label>
      <input
        type="password"
        v-model="user.password"
        class="form-control"
        id="password"
        aria-describedby="password"
      />
    </div>

    <!-- roles -->
    <div class="form-group">
      <label for="rolePermission">Roles</label>
      <v-select
        label="name"
        :options="roles"
        v-model="user.role_id"
        :reduce="role => role.id"
      ></v-select>
    </div>
    <button type="submit" class="btn btn--secondary" :disabled="isSubmitting">Save</button>
  </form>
</template>

<script>
export default {
  props: {
    roles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      user: {},
      isSubmitting: false
    }
  },
  methods: {
    async createUser() {
      this.isSubmitting = true
      try {
        const response = await this.$repos.userManagementRepo.createUser(this.user)
        this.showSuccessNotification(response.mesage)
        this.$router.push('/admin/users')
      }catch(error) {
        this.showErrorNotification(error.response.data.mesage)
      }finally {
        this.isSubmitting = false
      }
    }
  },
}
</script>