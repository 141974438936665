<template>
  <table class="table table-responsive table-bordered border-0" id="failedSettlementsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">Sent to RDM?</th>
        <th scope="col">Payment Log ID</th>
        <th scope="col">Bill Ref</th>
        <th scope="col">Bill ID</th>
        <th scope="col">Transaction Ref</th>
        <th scope="col">Settlement Method ID</th>
        <th scope="col">Settlement Date</th>
        <th scope="col">Amount (&#8358;) </th>
        <th scope="col">TPKID</th>
        <th scope="col">Settlement Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="settlement in tableData" :key="settlement.id">
        <td><h6><span class="badge badge-secondary">{{ settlement.rdm_updated }}</span></h6></td>
          <td>{{ settlement.payment_log_id }}</td>
          <td>{{ settlement.bill_ref }}</td>
          <td>{{ settlement.bill_id }}</td>
          <td>{{ settlement.transaction_ref_no }}</td>
          <td>{{ settlement.settlement_method_id }}</td>
          <td>{{ settlement.settlement_date | formatDate }}</td>
          <td>{{ new Intl.NumberFormat('en-GB').format(settlement.settlement_amount) }}</td>
          <td>{{ settlement.tbpkid }}</td>
          <td>{{ settlement.settlement_notes }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  }
}
</script>