<template>
  <div>
    <auth-topbar />
    <router-view />
    <auth-footer />
  </div>
</template>

<script>
import AuthTopbar from '@/components/navigation/Topbar'
import AuthFooter from '@/components/navigation/Footer'
export default {
  name: 'AuthLayout',
  components: {
    AuthTopbar,
    AuthFooter
  }
}
</script>