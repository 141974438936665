<template>
  <table class="table table-responsive table-bordered border-0" id="monthlyReportTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">S/N</th>
        <th scope="col">Month</th>
        <th scope="col">Total Notification</th>
        <th scope="col">Pay On Account (Total Matched)</th>
        <th scope="col">Manual Payments</th>
        <th scope="col">On Queue (Total Unmatched)</th>
        <th scope="col">Bill Settlement</th>
        <th scope="col">Total IGR</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ index + 1 }}</td>
        <td>{{ data.month }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.notification) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.poa) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.manual_poa) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.queue) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.settlement) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(data.notification) + Number(data.poa) + Number(data.queue) + Number(data.settlement)) }}</td>
      </tr>
      <tr>
        <td colspan="2"><span class="text--secondary font-weight-bold">Total</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.notification) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.poa) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.manual_poa) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.queue) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(totalReports.settlement) }}</span></td>
        <td><span class="text--secondary font-weight-bold">{{ new Intl.NumberFormat('en-GB').format(Number(totalReports.notification) + Number(totalReports.poa) + Number(totalReports.manual_poa) + Number(totalReports.queue) + Number(totalReports.settlement)) }}</span></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
   props: {
    tableData: {
      type: Array,
      default: () => []
    },
    totalReports: {
      type: Object,
      default: () => {
        //intentionally empty
      }
    },
  }
}
</script>