<template>
  <table class="table table-bordered weekly-stats mb-0">
    <caption></caption>
    <thead>
      <tr>
        <th scope="col">Days</th>
        <th scope="col">Pay On Account (&#8358;)</th>
        <th scope="col">Settlement (&#8358;)</th>
        <th scope="col">Total (&#8358;)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Sunday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Sunday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Sunday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Sunday) + Number(weeklyStats.settlement.Sunday)) }}</td>
      </tr>
      <tr>
        <td>Monday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Monday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Monday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Monday) + Number(weeklyStats.settlement.Monday)) }}</td>
      </tr>
      <tr>
        <td>Tuesday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Tuesday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Tuesday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Tuesday) + Number(weeklyStats.settlement.Tuesday)) }}</td>
      </tr>
      <tr>
        <td>Wednesday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Wednesday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Wednesday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Wednesday) + Number(weeklyStats.settlement.Wednesday)) }}</td>
      </tr>
      <tr>
        <td>Thursday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Thursday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Thursday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Thursday) + Number(weeklyStats.settlement.Thursday)) }}</td>
      </tr>
      <tr>
        <td>Friday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Friday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Friday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Friday) + Number(weeklyStats.settlement.Friday)) }}</td>
      </tr>
      <tr>
        <td>Saturday</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.pay_on_account.Saturday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(weeklyStats.settlement.Saturday) }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(Number(weeklyStats.pay_on_account.Saturday) + Number(weeklyStats.settlement.Saturday)) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    weeklyStats: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
  .weekly-stats {
    thead {
      tr {
        th {
          color: $secondary;
          font-size: 15px;
        }
      }
    }
    tbody {
      tr {
        td {
          color: $black;
          font-weight: 400
        }
      }
    }
  }
</style>