<template>
  <div>
    <!-- page data -->
    <template>
      <section class="d-flex justify-content-between align-items-center">
        <div class="section--title">
          <h1>Web pay notifications - {{ data.pay_item_id }}</h1>
          <hr />
        </div>
        <button class="btn btn--secondary" @click="$router.push('/admin/webpay-notifications')">Back</button>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0 col-12">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <export-actions
                  :table-data="data.web_payment_items"
                  filename="webpayNotifications.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light"
                    v-simple-print="'webPaymentDetailsTable'"
                  >
                    Print
                  </button>
                </export-actions>
              </div>
              <web-pay-notifications-table :table-data="data.web_payment_items" />
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="bill settlement"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <web-pay-notifications-table :table-data="data.web_payment_items" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import WebPayNotificationsTable from "@/components/tables/flowTables/WebPayPaymentDetails";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    FilterTable,
    WebPayNotificationsTable,
    VueHtml2pdf,
  },
  data() {
    return {

    };
  },
  methods: {
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    jsonFields() {
      return {
        "Tax Amount": "tax_amount",
        "Amount": "amount",
        "Bill Item Name": "bill_item_name",
        "Customer Name": "cust_name",
        "Bill Id": "bill_id",
        "Transaction Ref No": "txn_ref",
      };
    },
    data() {
      return this.$store.state.payment ? this.$store.state.payment : {};
    }
  },
  async mounted() {
    if(!this.$store.state.payment) {
      this.$router.push('/admin/webpay-notifications');
    }
  },
};
</script>