<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>CBS Queue</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <FilterTable
          :from-date="queryStrings.from_date"
          :to-date="queryStrings.to_date"
          :searchStr="queryStrings.search_str"
          v-on:update:fromDate="queryStrings.from_date = $event"
          v-on:update:toDate="queryStrings.to_date = $event"
          v-on:update:searchStr="queryStrings.search_str = $event"
          @flterTable="updateCbsQueueData(queryStrings, 'filter')"
        />
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Showing result for record date from {{ queryStrings.from_date |  formatDate}} to
                    <span v-if="queryStrings.to_date">
                      {{ queryStrings.to_date |  formatDate}}
                    </span>
                    <span v-else>
                      {{ new Date() |  formatDate}}
                    </span>
                  </p>
                </div>
                <export-actions
                  :table-data="cbsQueueData"
                  filename="cbs-queue.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light" v-simple-print="'cbsQueueData'"
                  >Print</button>
                </export-actions>
              </div>
              <cbs-Queue-table :table-data="cbsQueueData" />
            </div>
          </div>
        </div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Cbs queue data"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <cbs-Queue-table :table-data="cbsQueueData" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import CbsQueueTable from "@/components/tables/flowTables/CBSQueueTable";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    FilterTable,
    CbsQueueTable,
    VueHtml2pdf
  },
  data() {
    return {
      cbsQueueData : [],
      isLoadingPage: true,
      paginationData: {},
      queryStrings: {
        from_date: new Date().toISOString(),
        per_page: 30,
        search_str: '',
        processed: 0,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getCbsQueueData(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.cbsQueueRepo.getCbsQueuesData(qs);
        this.cbsQueueData = data.data
        const paginationData = {
          total: data.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page
        }
        this.paginationData = {...paginationData}
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
     clickCallback(page) {
      this.updateCbsQueueData({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updateCbsQueueData({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updateCbsQueueData({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    updateCbsQueueData(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
       if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = this.queryStrings
       this.serializeQs(qs)
      this.getCbsQueueData(qs, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        "Customer Name Bank": "customer_name_bank",
        "State": "state",
        "Approval Status": "approval_status",
        "Tax Payer Name Rdm": "tax_payer_name_rdm",
        "Customer Phone Number Bank": "customer_phone_number_bank",
        "Tax Payer Phone Number Rdm": "tax_payer_phone_number_rdm",
        "Amount": "amount",
        "Revenue Allocation": "revenue_allocation",
        "Rin Allocation": "rin_allocation",
        "Customer Type Bank": "customer_type_bank",
      }
    }
  },
  async mounted() {
    await this.getCbsQueueData(this.queryStrings, true)
  },
};
</script>