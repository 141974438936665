
export default (axios) => ({
  getWebPayNotifications(params) {
    return axios.get("/web_payments", { params })
  },
  downloadWebpayments(url){
    return axios.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then (response => {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'file.xlsx'
      link.click()
  })
  }
})