<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Report by Bank</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="row mb-3" @submit.prevent="updateBankRevenueReports()">
              <div class="col-md-5">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Year</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="years"
                      v-model="year"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Month</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="months"
                      label="label"
                      v-model="month"
                      :reduce="month => month.value"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <button class="btn btn--primary btn-block">Filter</button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table--caption">
                  <h3>Showing reports for {{month | formatMonth}}, {{year}}</h3>
                  <hr />
                </div>
                <bar-chart :chart-data='datacollection' :options='options' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <bank-reports-table :table-data="bankReports" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import BankReportsTable from '@/components/tables/reportTables/BankReportsTable'
import BarChart from "@/charts/BarChart.js";

const monthsArr = [
  {
    label: 'January',
    value: '01'
  },
  {
    label: 'Feburary',
    value: '02'
  },
  {
    label: 'March',
    value: '03'
  },
  {
    label: 'April',
    value: '04'
  },
  {
    label: 'May',
    value: '05'
  },
  {
    label: 'June',
    value: '06'
  },
  {
    label: 'July',
    value: '07'
  },
  {
    label: 'August',
    value: '08'
  },
  {
    label: 'September',
    value: '09'
  },
  {
    label: 'October',
    value: '10'
  },
  {
    label: 'November',
    value: '11'
  },
  {
    label: 'December',
    value: '12'
  },
]
export default {
  components: {
    BankReportsTable,
    BarChart
  },
  data() {
    return {
      isLoadingPage: true,
      backReports: [],
      totalRevenueReport: {},
      years: [],
      year: null,
      month: null,
      months: monthsArr,
      currentYear: new Date().getFullYear(),
      datacollection: {
        labels: [],
        datasets: [
          {
            label: "Bank Collections",
            backgroundColor: "#FF6384",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  filters: {
    formatMonth: function (value) {
      if (!value) return ''
    return monthsArr.find(m => m.value === value).label
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getBankRevenueReports(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data: {reports, year, month} } = await this.$repos.reports.getBankCollections(qs);
        const labels = reports.map(report => report.bank_name)
        this.datacollection.labels = labels
        const labelData = reports.map(report => report.amount)
        this.datacollection.datasets[0].data = labelData

        this.bankReports = [...reports]
        let startYear = Number(this.currentYear) - 20;
        let endYear = Number(this.currentYear)
        const yearsArray = []
        for (let i = startYear; i <= endYear; i++) {
          yearsArray.push((startYear++).toString())
        }
        this.years = [...new Set(yearsArray)]
        this.year = year
        this.month = month
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },

    updateBankRevenueReports() {
      const qs = {year: this.year, month: this.month}
      this.serializeQs(qs)
      this.getBankRevenueReports(qs, false)
    },
  },
  async mounted() {
    const qs = {year: this.year, month: this.month}
    this.serializeQs(qs)
    await this.getBankRevenueReports(qs, true)
  },
}
</script>