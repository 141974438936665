import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { apiClient } from "./plugins/axios";
import store from './store'
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
import repositories from "./repositories/index";
import notifications from "./plugins/notification";
import 'element-ui/lib/theme-chalk/index.css';
import { Pagination } from "element-ui";
import JsonExcel from "vue-json-excel";
import VueSimplePrintPlugin from 'vue-simple-print';
import VueSweetalert2 from 'vue-sweetalert2';
import ExportActions from '@/components/tables/exportActions/ExportActions'
import PreLoader from '@/components/shared/PreLoader'
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

const options = {
  confirmButtonColor: '#2c8968',
  cancelButtonColor: '#c00001',
};

Vue.use(VueSweetalert2, options);

Vue.component("ExportActions", ExportActions);
Vue.component("PreLoader", PreLoader);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueSimplePrintPlugin);

const token = window.localStorage.getItem("accessToken")
apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

Vue.filter('formatDate', function (value) {
  const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
  if (!value) return ''
  value = new Date(value)
  return value.toLocaleDateString("en-US", dateFormatOptions)
})

Vue.use(Pagination);
Vue.use(VueIziToast);
Vue.use(notifications)

Vue.config.productionTip = false

Vue.prototype.$repos = repositories(apiClient);


router.beforeEach((to, from, next) => {
  const accessToken = window.localStorage.getItem("accessToken")
  const isAuthenticated = accessToken !== null ? true : false
  if(isAuthenticated) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    store.dispatch('getUser', {vm: Vue})
  }
  // redirect already authenticated user
  if(isAuthenticated && to.path === '/') {
    next({
      path: '/admin/dashboard',
      query: { redirect: to.fullPath }
    })
  }
  if (to.name !== 'login' && !isAuthenticated) next({ name: 'login' })
  else next()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
