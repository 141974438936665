<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Report by Revenue Stream</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="row mb-3" @submit.prevent="updateRevenueReports(queryStrings, 'filter')">
              <div class="col-md-5">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Year</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="years"
                      v-model="year"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Month</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="months"
                      label="label"
                      v-model="month"
                      :reduce="month => month.value"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <button class="btn btn--primary btn-block">Filter</button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
                <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                  <div class="w-100">
                    <p class="text--secondary font-weight-bold mb-3">Report Result</p>
                  </div>
                  <export-actions
                    :table-data="revenueReports"
                    filename="revenue-reports.xls"
                    :json-fields="jsonFields"
                    @generatePDF="generatePDF"
                  >
                  <button class="ml-2 btn btn-sm btn-light" v-simple-print="'revenueReportsTable'">Print</button>
                  </export-actions>
                </div>
                <revenue-report-table :table-data="revenueReports" :total-revenue-report="totalRevenueReport" />
              </div>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Yearly reports"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <revenue-report-table :table-data="revenueReports" :total-revenue-report="totalRevenueReport" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import RevenueReportTable from '@/components/tables/reportTables/RevenueReportTable.vue'

export default {
  components: {
    VueHtml2pdf,
    RevenueReportTable
  },
  data() {
    return {
      isLoadingPage: true,
      revenueReports: [],
      totalRevenueReport: {},
      years: [],
      year: null,
      month: null,
      months: [
        {
          label: 'January',
          value: '01'
        },
        {
          label: 'Feburary',
          value: '02'
        },
        {
          label: 'March',
          value: '03'
        },
        {
          label: 'April',
          value: '04'
        },
        {
          label: 'May',
          value: '05'
        },
        {
          label: 'June',
          value: '06'
        },
        {
          label: 'July',
          value: '07'
        },
        {
          label: 'August',
          value: '08'
        },
        {
          label: 'September',
          value: '09'
        },
        {
          label: 'October',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'December',
          value: '12'
        },
      ],
      currentYear: new Date().getFullYear(),
      queryStrings: {
        per_page: 50,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getRevenueReports(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data: {reports, total, year, month} } = await this.$repos.reports.getRevenueBasedCollections(qs);
        this.revenueReports = []
        for (const property in reports) {
          this.revenueReports.push({...reports[property], type: property})
        }

        this.totalRevenueReport = {...total}
        let startYear = Number(this.currentYear) - 20;
        let endYear = Number(this.currentYear)
        const yearsArray = []

        for (let i = startYear; i <= endYear; i++) {
          yearsArray.push((startYear++).toString())
        }
        this.years = [...new Set(yearsArray)]
        this.year = year
        this.month = month
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },

    updateRevenueReports(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = {...this.queryStrings, year: this.year, month: this.month}
      this.serializeQs(qs)
      this.getRevenueReports(qs, false)
    },

    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
  },
  computed: {
    jsonFields() {
      return {
        "Revenue Stream": "type",
        "Manual POA": "manual_poa",
        "POA": "poa",
        "Queue": "queue",
        "Settlement": "settlement"
      }
    }
  },
  async mounted() {
    await this.getRevenueReports({...this.queryStrings, year: this.year}, true)
  },
}
</script>