<template>
  <div class="bg-light py-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="bg-white p-4">
            <div class="card border-0">
              <div class="card-header bg-white">
                <h5>Login</h5>
              </div>
              <div class="card-body pt-4">
                <login-form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/forms/auth/LoginForm'
export default {
  name: 'Login',
  components: {
    LoginForm
  }
}
</script>