
export default (axios) => ({
  getBillSettlements(queryStrings) {
    return axios.get("/settlements", {
      params: queryStrings
    })
  },
  getFailedSettlements(queryStrings) {
    return axios.get("/settlements", {
      params: queryStrings
    })
  },
  getSingleSettlement(payload) {
    return axios.get(`/settlements${payload.id}`)
  }
})