<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>General Report</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="mb-3" @submit.prevent="updateGeneralReports(queryStrings, 'filter')">
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group row justify-content-end">
                    <label class="col-sm-3 col-form-label text-right">Revenue Stream</label>
                    <div class="col-sm-9">
                      <v-select
                        :options="revenueStream"
                        v-model="queryStrings.revenue_stream"
                        label="Revenue_Stream_Name"
                        :reduce="stream => stream.Revenue_Stream_ID"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group row justify-content-end">
                    <label class="col-sm-3 col-form-label text-right">Date</label>
                    <div class="col-sm-9">
                      <input type="date" v-model="queryStrings.from_date" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group row">
                    <label class="col-sm-3 col-form-label text-right">Tax Payer Name</label>
                    <div class="col-sm-9">
                      <input type="text" placeholder="Tax payer name" v-model="queryStrings.tax_payer_name" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-7">
                  <div class="row justify-content-end">
                    <div class="col-md-3">
                      <button class="btn btn--primary btn-block">Filter</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                  <div class="w-100">
                    <p class="text--secondary font-weight-bold mb-3">
                      Reports
                    </p>
                  </div>
                  <export-actions
                    :table-data="generalReports"
                    filename="General_Reports.xls"
                    :json-fields="jsonFields"
                    @generatePDF="generatePDF"
                  >
                    <button
                      class="ml-2 btn btn-sm btn-light" v-simple-print="'generalReportsTable'"
                    >Print</button>
                  </export-actions>
                </div>
                <general-report-table :table-data="generalReports" />
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="paginationData.total"
                  :current-page.sync="paginationData.page"
                  :page-size.sync="paginationData.limit"
                  :hide-on-single-page="true"
                  :pager-count="5"
                  @current-change="clickCallback"
                  @prev-click="prevPage"
                  @next-click="nextPage"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="General Reports"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <general-report-table :table-data="generalReports" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import GeneralReportTable from '@/components/tables/reportTables/GeneralReportTable'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    GeneralReportTable,
    VueHtml2pdf
  },
   data() {
    return {
      months: [
        {
          label: 'January',
          value: '01'
        },
        {
          label: 'Feburary',
          value: '02'
        },
        {
          label: 'March',
          value: '03'
        },
        {
          label: 'April',
          value: '04'
        },
        {
          label: 'May',
          value: '05'
        },
        {
          label: 'June',
          value: '06'
        },
        {
          label: 'July',
          value: '07'
        },
        {
          label: 'August',
          value: '08'
        },
        {
          label: 'September',
          value: '09'
        },
        {
          label: 'October',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'December',
          value: '12'
        },
      ],
      generalReports: [],
      isLoadingPage: true,
      paginationData: {},
      revenueStream: [],
      queryStrings: {
        from_date: new Date().toISOString(), //'1970-01-01',
        search_str: '',
        revenue_stream: '',
        tax_payer_name: '',
        per_page: 100,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getGeneralReports(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data: {revenueStream, reports} } = await this.$repos.reports.getGeneralCollections(qs);
        this.revenueStream = [...revenueStream]
        this.generalReports = [...reports.data]
        const paginationData = {
          total: reports.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page
        }
        this.paginationData = {...paginationData}
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    clickCallback(page) {
      this.updateGeneralReports({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updateGeneralReports({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updateGeneralReports({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    updateGeneralReports(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = {...this.queryStrings, year: this.year, month: this.month}
      this.serializeQs(qs)
      this.getGeneralReports (qs, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        Amount: "amount",
        "Alternate Customer Reference": "alternate_cust_reference",
        "Bank Code": "bank_code",
        "Bank Name": "bank_name",
        "Branch Name": "branch_name",
        "Channel Name": 'channel_name',
        "Customer Phone Number": "customer_phone_number",
        "Deposit Slip Number": "deposit_slip_number",
        "Depositor Name": "deposit_name",
        "Fee Name": 'fee_name',
        "Ftp Password": 'ftp_password',
        "Ftp url": 'ftp_url',
        "Ftp username": 'ftp_username',
        "Id": 'id',
        "Institution Id": 'insitution_id',
        "Institution Name": 'insitution_name',
        "Location": 'location',
        "Original Payment Log Id": 'original_payment_log_id',
        "Original Payment Reference": 'original_payment_reference',
        "Other Customer Info": 'other_customer_info',
        "Payment Status": 'payment_Status',
        "Payment Currency": 'payment_currency',
        "Payment Date": 'payment_date',
        "Payment Log Id": 'payment_log_id',
        "Payment Method": 'payment_method',
      }
    }
  },
  async mounted() {
    await this.getGeneralReports(this.queryStrings, true)
  },
}
</script>