<template>
  <form @submit.prevent="editRole">
    <!-- role name -->
    <div class="form-group">
      <label for="roleName">Role</label>
      <input
        type="text"
        v-model="role.name"
        class="form-control"
        id="roleName"
        aria-describedby="roleName"
      />
    </div>
    <div class="form-group">
      <label for="rolePermission">Permission</label>
      <v-select
        label="name"
        :options="permissions"
        v-model="role.permissions"
        taggable
        multiple
        :reduce="permission => permission.id"
      ></v-select>
    </div>
    <button class="btn btn--secondary" :disabled="isSubmitting">Save</button>
  </form>
</template>

<script>
export default {
  props: {
    permissions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      isSubmitting: false,
      role: {}
    }
  },
  methods: {
    setRole(role) {
      this.role = role
    },
    async editRole() {
      this.isSubmitting = true
      try {
        await this.$repos.userManagementRepo.updateRole(this.role);
        this.$emit('updateRolesTable')
        $('#rolesModal').modal('hide')
        this.role = {...roleObj}
        this.showSuccessNotification('Role ypdated successfully');
      }catch(error) {
        console.log(error)
      }finally {
        this.isSubmitting = false
      }
    }
  },
}
</script>