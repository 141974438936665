import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    payment: null
  },
  mutations: {
    commitUser (state, data) {
      state.user = data
    },
    setPaymentDetails (state, payment) {
      state.payment = payment
    }
  },
  actions: {
    async getUser ({ commit }) {
      try {
        const { data } = await this._vm.$repos.authRepo.getUser()
        commit('commitUser', data)
      }catch(error) {
        console.log(error)
      }
    }
  },
  modules: {
  }
})
