<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Yearly Collections Report</h1>
          <hr />
        </div>
      </section>
      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="row mb-3" @submit.prevent="updateYearlyReports(queryStrings, 'filter')">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Year</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="years"
                      placeholder="Select a year"
                      v-model="year"
                    ></v-select>
                  </div>
                </div>
                <div>
                  <button class="btn btn--primary btn-block">Filter</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
                <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                  <div class="w-100">
                    <p class="text--secondary font-weight-bold mb-3">Report Result</p>
                  </div>
                  <export-actions
                    :table-data="yearlyReports"
                    filename="yearly-reports.xls"
                    :json-fields="jsonFields"
                    @generatePDF="generatePDF"
                  >
                    <button class="ml-2 btn btn-sm btn-light" v-simple-print="'yearlyReportTable'">Print</button>
                  </export-actions>
                </div>
                <yearly-report-table :table-data="yearlyReports" :total-reports="totalReports" />
              </div>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Yearly reports"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <yearly-report-table :table-data="yearlyReports" :totalReports="totalReports" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import YearlyReportTable from '@/components/tables/reportTables/YearlyReportTable'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    YearlyReportTable,
    VueHtml2pdf
  },
  data() {
    return {
      isLoadingPage: true,
      yearlyReports: [],
      years: [],
      totalReports: {},
      paginationData: {},
      currentYear: new Date().getFullYear(),
      year: null,
      queryStrings: {
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem] === null || qs[elem] === '') {
          delete qs[elem];
        }
      });
    },
    async getYearlyReportsData(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data: {reports, total} } = await this.$repos.reports.getYearlyCollections(qs);
        this.yearlyReports = [...reports]
        this.totalReports = {...total}

        let startYear = 2017 //Number(this.currentYear) - 20;
        let endYear = Number(this.currentYear)
        const yearsArray = []

        for (let i = startYear; i <= endYear; i++) {
          yearsArray.push((startYear++).toString())
        }
        this.years = [...new Set(yearsArray)]
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    updateYearlyReports(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue

      if(type === 'filter') {
        this.queryStrings.page = 1
      }

      const qs = {...this.queryStrings, year: this.year}
      this.serializeQs(qs)
      this.getYearlyReportsData(qs, false)
    },
    clickCallback(page) {
      this.updateYearlyReports({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updateYearlyReports({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updateYearlyReports({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
  },
  computed: {
    jsonFields() {
      return {
        Year: "year",
        "POA": "poa",
        "Queue": "queue",
        "Settlement": "settlement",
        "Manual POA": "manual_poa",
      }
    }
  },
  async mounted() {
    await this.getYearlyReportsData(this.queryStrings, true)
  },
}
</script>