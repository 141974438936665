<template>
	<div class="modal fade" id="paymentsModal" tabindex="-1" aria-labelledby="paymentsLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content rounded-0">
				<div class="modal-header">
					<h5 class="modal-title" id="paymentsLabel">Payment Log ID ({{ paymentLogId }})</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
				</div>
				<div class="modal-body">
					<div class="accordion" id="paymentItemAccordion">
						<div class="card mb-2 custom-accordion-control" v-for="item in paymentItems" :key="item.id">
							<div class="card-header bg--primary text-white p-0" id="headingOne">
								<h2
									:data-target='`#collapse${item.id}`'
									class="mb-0 text-left pointer fs-20 w-100 p-3"
									style="cursor: pointer;"
									data-toggle="collapse"
									aria-expanded="true"
									ref="customControlAccordion"
									:aria-controls="`collapse${item.id}`"
								>
									{{ item.item_name }}
								</h2>
							</div>

							<div
								:id="`collapse${item.id}`"
								class="collapse"
								aria-labelledby="headingOne"
								data-parent="#paymentItemAccordion"
							>
								<ul class="list-group list-group-flush">
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Item name</div>
											<div>{{ item.item_name }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Item amount</div>
											<div>{{ new Intl.NumberFormat('en-GB').format(item.item_amount) }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Item code</div>
											<div>{{ item.item_code }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Lead bank code</div>
											<div>{{ item.lead_bank_code }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Lead bank CBN code</div>
											<div>{{ item.lead_bank_cbn_code }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Lead bank name</div>
											<div>{{ item.lead_bank_name }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
											<div class="text-muted">Category name</div>
											<div>{{ item.category_name }}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		paymentItems : {
			type: Array,
			default: () => [],
			required: false
		},
		paymentLogId: {
			type: Number,
			default: () => 0,
			required: false
		}
	},
	methods: {
		toggleFirstAccordion() {
			const accordions = this.$refs.customControlAccordion
			accordions[0].click()
		}
	},
	updated() {
		this.$nextTick(function () {
			this.toggleFirstAccordion()
		})
	}
}
</script>