<template>
  <table class="table table-responsive table-bordered border-0" id="cbsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">State</th>
        <th scope="col">Actions</th>
        <th scope="col">Payment Log ID</th>
        <th scope="col">Customer Name (Bank)</th>
        <th scope="col">TaxPayer Name (RDM)</th>
        <th scope="col">Customer Phone Number (Bank)</th>
        <th scope="col">TaxPayer Phone Number(RDM)</th>
        <th scope="col">Amount 	(&#8358;) </th>
        <th scope="col">Revenue Allocation</th>
        <th scope="col">Bank</th>
        <th scope="col">Branch</th>
        <th scope="col">Payment Date</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="data in tableData" :key="data.id">
        <td>{{ data.state }}</td>
        <td>Btn</td>
        <td>{{ data.payment_log_id }}</td>
        <td>{{ data.customer_name_bank }}</td>
        <td>{{ data.tax_payer_name_rdm }}</td>
        <td>{{ data.customer_phone_number_bank }}</td>
        <td>{{ data.tax_payer_phone_number_rdm }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.amount) }}</td>
        <td>{{ data.revenue_allocation }}</td>
        <td>{{ data.rin_bank }}</td>
        <td>{{ data.customer_type_bank }}</td>
        <td>{{ data.created_at | formatDate }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ExportActions from '../exportActions/ExportActions'
export default {
  components: {
    ExportActions
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  }
}
</script>