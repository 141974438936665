export default (axios) => ({
  // roles
  getRoles() {
    return axios.get("/roles")
  },
  createRole(payload) {
    return axios.post("/roles", payload)
  },
  updateRole(payload) {
    return axios.put(`/roles/${payload.id}`, payload)
  },
  deleteRole(roleId) {
    return axios.delete(`/roles/${roleId}`, )
  },

  // permissions
  getPermissions() {
    return axios.get("/permissions")
  },
  createPermission(payload) {
    return axios.post("/permissions", payload)
  },
  updatePermission(payload) {
    return axios.put(`/permissions/${payload.id}`, payload)
  },
  deletePermission(permissionId) {
    return axios.delete(`/permissions/${permissionId}`)
  },

  //user
  getUsers(queryStrings) {
    return axios.get("/users", {
      params: queryStrings
    })
  },
  createUser(payload) {
    return axios.post("/users", payload)
  },
  updateUser(payload) {
    return axios.put("/users", payload)
  },
  deleteUser(userId) {
    return axios.delete(`/users/${userId}`)
  }
})