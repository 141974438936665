<template>
  <form>
    <!-- email` -->
    <div class="form-group">
      <label for="userEmail">Email address</label>
      <input
        type="email"
        v-model="user.email"
        class="form-control"
        id="userEmail"
        aria-describedby="userEmail"
      />
    </div>

    <!-- password -->
    <div class="form-group">
      <label for="userPassword">Password</label>
      <input
        type="password"
        v-model="user.password"
        class="form-control"
        id="userPassword"
      />
    </div>

    <!-- submit button -->
    <div class="d-flex justify-content-end">
      <button
        type="submit"
        class="btn btn--primary float-right mt-2"
        :disabled="isSubmitting"
        @click.prevent="performLogin"
      >
        Login
      </button>
    </div>
  </form>
</template>
<script>
import { apiClient } from '../../../plugins/axios'
export default {
  data() {
    return {
      isSubmitting: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async performLogin() {
      this.isSubmitting = true
      try {
        let { data: { access_token } } = await this.$repos.authRepo.login(this.user);
        window.localStorage.setItem("accessToken", access_token);
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        this.showSuccessLoginNotification();
        setTimeout(() => {
          this.$router.push("/admin/dashboard");
        }, 1500);
      } catch (error) {
        this.showErrorLoginNotification(error.response.data.message || 'Invalid Credentials')
      }finally {
        this.isSubmitting = false
      }
    },
  },
};
</script>