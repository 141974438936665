<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Users</h1>
          <hr />
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
                <div class="d-flex border-bottom mb-4 px-2 align-items-center justify-content-between">
                  <div class="mb-3">
                    <p class="text--secondary font-weight-bold mb-0">
                      Create
                    </p>
                  </div>
                </div>
                <create-user-form :roles="roles" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import CreateUserForm from '@/components/forms/users/user/CreateUserForm'
export default {
  components: {
    CreateUserForm
  },
  data() {
    return {
      isLoadingPage: true,
      roles: []
    }
  },
  methods: {
    async getRoles() {
      try {
        const { data: roles } = await this.$repos.userManagementRepo.getRoles();
        this.roles = roles
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    }
  },
  async mounted() {
    await this.getRoles()
  },
}
</script>