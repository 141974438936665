<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Failed Settlements</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <FilterTable
          :from-date="queryStrings.from_date"
          :to-date="queryStrings.to_date"
          :searchStr="queryStrings.search_str"
          v-on:update:fromDate="queryStrings.from_date = $event"
          v-on:update:toDate="queryStrings.to_date = $event"
          v-on:update:searchStr="queryStrings.search_str = $event"
          @flterTable="updateFailedSettlement(queryStrings, 'filter')"
        />
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Showing result for record date from {{ queryStrings.from_date |  formatDate}} to
                    <span v-if="queryStrings.to_date">
                      {{ queryStrings.to_date |  formatDate}}
                    </span>
                    <span v-else>
                      {{ new Date() |  formatDate}}
                    </span>
                  </p>
                </div>

                <export-actions
                  :table-data="failedSettlements"
                  filename="Failed_Settlement.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light" v-simple-print="'failedSettlementsTable'"
                  >Print</button>
                </export-actions>
              </div>
              <failed-settlement-table :table-data="failedSettlements" />
              <el-pagination
                background
                layout="prev, pager, next"
                :total="paginationData.total"
                :current-page.sync="paginationData.page"
                :page-size.sync="paginationData.limit"
                :hide-on-single-page="true"
                :pager-count="5"
                @current-change="clickCallback"
                @prev-click="prevPage"
                @next-click="nextPage"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="failed settlement"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <failed-settlement-table :table-data="failedSettlements" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import FailedSettlementTable from '../../components/tables/utilitiesTables/FailedSettlementTable.vue';
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    FilterTable,
    FailedSettlementTable,
    VueHtml2pdf
  },
  data() {
    return {
      isLoadingPage: true,
      failedSettlements: [],
      paginationData: {},
      queryStrings: {
        from_date: '1970-01-01',
        search_str: '',
        per_page: 50,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getFailedSettlements(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.utilities.getFailedSettlements(qs);
        const paginationData = {
          total: data.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page
        }
        this.paginationData = {...paginationData}
        this.failedSettlements = data.settlements.data
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    clickCallback(page) {
      this.updateFailedSettlement({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updateFailedSettlement({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updateFailedSettlement({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    updateFailedSettlement(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = this.queryStrings
      this.getFailedSettlements(qs, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        "Sent To RDM": "rdm_updated",
        "Payment Lod ID": "payment_log_id",
        "Bill reference": "bill_ref",
        "Bill Id": "bill_id",
        "Transaction Ref No": "transaction_ref_no",
        "Settlement Method ID": 'settlement_method_id',
        "Settlement Date": "settlement_date",
        "Settlement Amount": "settlement_amount",
        "TBPKID": 'tbpkid'
      }
    }
  },
  async mounted() {
    await this.getFailedSettlements(this.queryStrings, true)
  },
};
</script>