import authRepo from "./AuthRepo";
import CbsPoa from "./CbsPoa.js";
import PayDirectNotification from "./PayDirectNotification.js";
import CbsQueue from "./CbsQueue.js";
import PayDirectApiLogs from "./PayDirectApiLogs.js";
import BillSettlement from './BillSettlement.js'
import PreServiceBills from './PreServiceBills'
import Payment from "./Payment";
import CBSPaydirect from './CbsPayDirect'
import UserManagement from './UserManagement'
import Analytics from "./Analytics"
import Unprocessed from './Unprocessed'
import Utilities from "./Utilities";
import Reports from './Reports';
import WebPayNotifications from './WebPayNotifications'

export default (axios) => {
	return {
		authRepo: authRepo(axios),
		cbsPoaRepo: CbsPoa(axios),
		payDirectNotificationRepo: PayDirectNotification(axios),
		cbsQueueRepo: CbsQueue(axios),
		payDirectApiLogsRepo: PayDirectApiLogs(axios),
		billSettlementRepo: BillSettlement(axios),
		paymentRepo: Payment(axios),
		preServiceBillsRepo: PreServiceBills(axios),
		CBSPaydirectRepo: CBSPaydirect(axios),
		userManagementRepo: UserManagement(axios),
		analytics: Analytics(axios),
		unprocessed: Unprocessed(axios),
		utilities: Utilities(axios),
		reports: Reports(axios),
		webPay: WebPayNotifications(axios)
	}
}