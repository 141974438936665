<template>
  <div>
    <div class="table-responsive table-bordered border-0" id="paymentsTable">
      <table class="table table-bordered border-0">
        <caption class="d-none"></caption>
        <thead>
          <tr>
            <th scope="col">Processed</th>
            <th scope="col">Payment Log ID</th>
            <th scope="col">Customer Ref</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Customer Phone Number</th>
            <th scope="col">Amount (&#8358;) </th>
            <th scope="col">Depositor Name</th>
            <th scope="col">Settlement Date</th>
            <th scope="col">Payment Date</th>
            <th scope="col">Payment Time</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in tableData" :key="payment.id">
            <td><h6><span class="badge badge-secondary">{{ payment.processed }}</span></h6></td>
            <td>{{ payment.payment_log_id }}</td>
            <td>{{ payment.cust_reference ? payment.cust_reference : '--N/A--'}}</td>
            <td>{{ payment.customer_name }}</td>
            <td>{{ payment.customer_phone_number }}</td>
            <td>{{ new Intl.NumberFormat('en-GB').format(payment.amount) }}</td>
            <td>{{ payment.depositor_name }}</td>
            <td>{{ payment.settlement_date }}</td>
            <td>{{  payment.created_at }}</td>
            <td>15:42:12</td>
            <td>
              <button
                @click="setPaymentItemDetails(payment)"
                type="button"
                data-toggle="modal"
                data-target="#paymentsModal"
                class="btn btn-success btn-sm"
              >
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <payment-items-modal
      :payment-items="paymentItems"
      :payment-log-id="paymentLogId"
    />
  </div>
</template>

<script>
import ExportActions from '../exportActions/ExportActions'
import PaymentItemsModal from '../../modals/PaymentItemsModal'
export default {
  components: {
    PaymentItemsModal,
    ExportActions
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      paymentItems: [],
      paymentLogId: null
    }
  },
  methods: {
    setPaymentItemDetails(payment) {
      this.paymentItems = [...payment.payment_items]
      this.paymentLogId = payment.payment_log_id
    }
  }
}
</script>

<style lang="scss">
  th {
    color: $secondary;
    font-size: 14px;
    white-space: nowrap;
  }
  .table-responsive tr td,
    .table-responsive tr button {
    font-size: 13px;
  }
  .el-pagination {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .el-pager {
    list-style: none;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #337ab7 !important;
    color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #337ab7 !important;
  }
</style>