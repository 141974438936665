<template>
  <div class="mw-100">
    <table class="table table-bordered border-0 w-100 table-responsive bg-white" id="webPaymentDetailsTable">
      <caption class="d-none"></caption>
      <thead>
        <tr>
          <th scope="col">S/N</th>
          <th scope="col">TaxPayer ID</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Bill Ref</th>
          <th scope="col">Payment Ref</th>
          <th scope="col">Amount (&#8358;)</th>
          <th scope="col">Payment Dates</th>
          <th scope="col">Payment Status</th>
          <th scope="col">Transaction Description</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in tableData" :key="payment.id">
          <td>{{ index + 1 }}</td>
          <td>{{ payment.taxpayer_id }}</td>
          <td>{{ payment.cust_name }}</td>
          <td>{{ payment.web_payment_items[0] ? payment.web_payment_items[0].bill_item_ref : "" }}</td>
          <td><h6><span class="badge badge-secondary">{{ payment.payment_ref }}</span></h6></td>
          <td>{{ new Intl.NumberFormat('en-GB').format(payment.amount) }}</td>
          <td>{{ payment.payment_date | formatDate }}</td>
          <td>{{ payment.payment_status }}</td>
          <td>{{ payment.response_description }}</td>
          <td>
            <button
              @click="setPaymentDetails(payment)"
              type="button"
              class="btn btn-success"
              data-toggle="modal"
              data-target="#settlementModal"
            >
              View
            </button>
            <button
              @click="setPaymentDetails(payment, true)"
              type="button"
              class="btn btn--secondary d-block mt-2"
            >
              Export
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <payment-items-modal
      :payment-items="paymentItems"
      :pay-item-id="payItemId"
    />
  </div>
</template>

<script>
import PaymentItemsModal from "../../modals/WebPayNotificationItems.vue"
export default {
  components: {
    PaymentItemsModal
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
      paymentItems: [],
      payItemId: ""
    }
  },
  methods: {
    setPaymentDetails(payment, redirect) {
      this.paymentItems = [...payment.web_payment_items]
      this.payItemId = payment.pay_item_id
      if(redirect) {
        this.$store.commit('setPaymentDetails', payment)
        this.$router.push(`/admin/webpay-notifications/${this.payItemId}/export`)
      }
    }
  }
}
</script>