<template>
  <div class="card border-0 shadow-none">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="table--caption">
            <h3>Filter</h3>
            <hr />
          </div>
        </div>
        <div class="col-md-4 col-12 my-3">
          <!-- start date -->
          <div class="row mb-3 no-gutters align-items-center">
            <label for="fromDate" class="col-3 col-form-label">Start Date</label>
            <div class="col-8">
              <input
                type="date"
                class="form-control"
                :value="fromDate"
                id="fromDate"
                @change="$emit('update:fromDate', $event.target.value)"
              >
            </div>
          </div>
        </div>

        <!-- end date -->
        <div class="col-md-4 col-12 my-3">
          <div class="row mb-3 no-gutters align-items-center">
            <label for="toDate" class="col-3 col-form-label">End Date</label>
            <div class="col-8">
             <input
                type="date"
                class="form-control"
                :value="toDate"
                id="toDate"
                @change="$emit('update:toDate', $event.target.value)"
              >
            </div>
          </div>
        </div>
      </div>

      <!-- search -->
      <div class="row mb-3">
        <div class="col-sm-10">
          <input
            type="search"
            class="form-control"
            id="searchStr"
            :value="searchStr"
            @change="$emit('update:searchStr', $event.target.value)"
          >
        </div>
        <div class="col-sm-2">
          <button class="btn btn--secondary" @click="$emit('flterTable')">Filter</button>
        </div>
        <div v-if="enableDownload" class="col-sm-2">
          <button class="btn btn--primary" @click="$emit('download')">Download</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fromDate : {
      type: String,
      required: false,
      default: null
    },
    toDate : {
      type: String,
      reqired: false,
      default:null
    },
    searchStr : {
      type: String,
      reqired: false,
      default:null
    },
    enableDownload : {
      type: Boolean,
      reqired: false,
      default: false
    }
  }
}
</script>
<style lang="scss">
  .btn--secondary {
    background: $secondary;
    color: $white;
    &:hover {
      color: $white;
    }
  }
</style>