import Vue from "vue";
import VueRouter from "vue-router";

// import layouts
import AuthLayout from "@/layouts/AuthLayout";
import DefaultLayout from "@/layouts/DefaultLayout";

// import auth paths
import Login from "@/views/auth/Login";

// flowtables
import PayDirectNotifications from "@/views/flowTables/PayDirectNotifications";
import PreServiceBills from "@/views/flowTables/PreServiceBills";
import CBSPoa from "@/views/flowTables/CBSPoa";
import BillSettlement from "@/views/flowTables/BillSettlement";
import Unprocessed from "@/views/flowTables/Unprocessed";
import CBSQueue from "@/views/flowTables/CBSQueue";
import CBSPaydirect from "@/views/flowTables/CBSPaydirect";
import Payments from "@/views/flowTables/Payments";
import PayDirectNotificationTrace from "@/views/flowTables/PayDirectNotificationTrace";
import WebpayNotifications from "@/views/flowTables/WebpayNotifications";
import WebpayNotificationPayments from "@/views/flowTables/WebpayNotificationPayments";

// Reports
import BankReport from "@/views/reportCollection/BankReport";
import BankReportDetails from "@/views/reportCollection/BankReportDetails";
import GeneralReport from "@/views/reportCollection/GeneralReport";
import MonthlyReport from "@/views/reportCollection/MonthlyReport";
import RevenueReport from "@/views/reportCollection/RevenueReport";
import ReversedReport from "@/views/reportCollection/ReversedReport";
import YearlyReport from "@/views/reportCollection/YearlyReport";
import ChannelReports from "@/views/reportCollection/ChannelReports";

// utilities
import FailedPoa from "@/views/utilities/FailedPoa";
import FailedSettlement from "@/views/utilities/FailedSettlement";

// user management
import Users from "@/views/userManagement/users/UsersList";
import CreateUser from "@/views/userManagement/users/CreateUser";
import Roles from "@/views/userManagement/Roles";
import Permissions from "@/views/userManagement/Permissions";

// dashboard
import Dashboard from "@/views/dashboard/Dashboard";

// unprocessed data
import UnprocessedNotifications from "@/views/unprocessed/Notifications";
import UnprocessedCbsPaydirect from "@/views/unprocessed/CBSPaydirect";
import UnprocessedPerserviceBills from "@/views/unprocessed/PreServiceBills";
import UnprocessedPOA from "@/views/unprocessed/POA";
import UnprocessedSettlements from "@/views/unprocessed/Settlements";
import UnprocessedCBSQueue from "@/views/unprocessed/CBSQueue";

// Not found
import NotFound from "@/views/404/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/",
        component: Login,
        name: "login",
      },
    ],
  },
  {
    path: "/admin",
    component: DefaultLayout,
    children: [
      {
        path: "dashboard",
        component: Dashboard,
        name: "dashboard",
        meta: { requiresAuth: true },
      },

      // flow tables start
      {
        path: "paydirect-notifications",
        component: PayDirectNotifications,
        name: "PaydirectNotifications",
        meta: { requiresAuth: true },
      },
      {
        path: "preservice-bills",
        component: PreServiceBills,
        name: "PreServiceBills",
        meta: { requiresAuth: true },
      },
      {
        path: "cbs-poa",
        component: CBSPoa,
        name: "CBSPoa",
        meta: { requiresAuth: true },
      },
      {
        path: "bill-settlement",
        component: BillSettlement,
        name: "BillSettlement",
        meta: { requiresAuth: true },
      },
      {
        path: "unprocessed",
        component: Unprocessed,
        name: "Unprocessed",
        meta: { requiresAuth: true },
      },
      {
        path: "cbs-queue",
        component: CBSQueue,
        name: "CBSQueue",
        meta: { requiresAuth: true },
      },
      {
        path: "cbs-paydirect",
        component: CBSPaydirect,
        name: "CBSPaydirect",
        meta: { requiresAuth: true },
      },
      {
        path: "payments",
        component: Payments,
        name: "Payments",
        meta: { requiresAuth: true },
      },
      {
        path: "notification/:id/trace",
        component: PayDirectNotificationTrace,
        props: (route) => ({ id: route.params.id }),
        meta: { requiresAuth: true },
      },
      {
        path: "webpay-notifications",
        component: WebpayNotifications,
        meta: { requiresAuth: true },
      },
      {
        path: "webpay-notifications/:id/export",
        component: WebpayNotificationPayments,
        meta: { requiresAuth: true },
        props: true
      },
      // flow tables end

      // Reports
      {
        path: "bank-report",
        component: BankReport,
        name: "BankReport",
        meta: { requiresAuth: true },
      },
      {
        path: "bank-report-details/:bankCode",
        component: BankReportDetails,
        props: (route) => ({ bankCode: route.params.bankCode }),
        name: "BankReportDetails",
        meta: { requiresAuth: true },
      },
      {
        path: "general-report",
        component: GeneralReport,
        name: "GeneralReport",
        meta: { requiresAuth: true },
      },
      {
        path: "monthly-report",
        component: MonthlyReport,
        name: "MonthlyReport",
        meta: { requiresAuth: true },
      },
      {
        path: "revenue-report",
        component: RevenueReport,
        name: "RevenueReport",
        meta: { requiresAuth: true },
      },
      {
        path: "reversed-report",
        component: ReversedReport,
        name: "ReversedReport",
        meta: { requiresAuth: true },
      },
      {
        path: "yearly-report",
        component: YearlyReport,
        name: "YearlyReport",
        meta: { requiresAuth: true },
      },
      {
        path: "channel-report",
        component: ChannelReports,
        name: "ChannelReports",
        meta: { requiresAuth: true },
      },
      // Reports end

      // Utilities start
      {
        path: "failed-poa",
        component: FailedPoa,
        name: "FailedPoa",
        meta: { requiresAuth: true },
      },
      {
        path: "failed-settlement",
        component: FailedSettlement,
        name: "FailedSettlement",
        meta: { requiresAuth: true },
      },
      //Utilities end

      // Users management start
      {
        path: "users",
        component: Users,
        name: "Users",
        meta: { requiresAuth: true },
      },
      {
        path: "users/create-user",
        component: CreateUser,
        name: "CreateUser",
        meta: { requiresAuth: true },
      },
      {
        path: "roles",
        component: Roles,
        name: "Roles",
        meta: { requiresAuth: true },
      },
      {
        path: "permissions",
        component: Permissions,
        name: "Permissions",
        meta: { requiresAuth: true },
      },

      // unprocessed data
      {
        path: "unprocessed-notifications",
        component: UnprocessedNotifications,
        name: "UnprocessedNotifications",
        meta: { requiresAuth: true },
      },
      {
        path: "unprocessed-cbspaydirect",
        component: UnprocessedCbsPaydirect,
        name: "UnprocessedCbsPaydirect",
        meta: { requiresAuth: true },
      },
      {
        path: "unprocessed-preservice-bills",
        component: UnprocessedPerserviceBills,
        name: "UnprocessedPerserviceBills",
        meta: { requiresAuth: true },
      },
      {
        path: "unprocessed-poa",
        component: UnprocessedPOA,
        name: "UnprocessedPOA",
        meta: { requiresAuth: true },
      },
      {
        path: "unprocessed-settlements",
        component: UnprocessedSettlements,
        name: "UnprocessedSettlements",
        meta: { requiresAuth: true },
      },
      {
        path: "unprocessed-cbs-queue",
        component: UnprocessedCBSQueue,
        name: "UnprocessedCBSQueue",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: "*",
    component: NotFound,
    redirect: {
      name: "404",
      path: "/404",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
