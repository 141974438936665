<template>
  <nav class="navbar navbar-light bg-white py-0">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img
          src="@/assets/images/nav-logo-one.png"
          class="mr-3"
          height="55"
          alt="ERAS logo"
        >
        <img
          src="@/assets/images/eras-logo.png"
          height="60"
          alt="ERAS logo"
        >
      </a>
      <slot />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AuthNav'
}
</script>