<template>
  <form @submit.prevent="$emit('submit')">
    <!-- permission name -->
    <div class="form-group">
      <label for="permissionName">Permission</label>
      <input
        type="text"
        :value="permission.name"
        @change="$emit('update:permission', $event.target.value)"
        class="form-control"
        id="permissionName"
        aria-describedby="permissionName"
      />
    </div>
    <button class="btn btn--secondary" type="submit" :disabled="isSubmitting">Save</button>
  </form>
</template>

<script>
export default {
  props: {
    permission: {
      type: Object,
      required: false
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
}
</script>