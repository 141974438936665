<template>
  <div>
    <div class="d-flex border-bottom mb-4 px-2 align-items-center justify-content-between">
      <div class="mb-3">
        <p class="text--secondary font-weight-bold mb-0">
          List
        </p>
      </div>
      <button class="btn btn--secondary mb-3" @click="$emit('showPermissionForm', {type: 'create', name: ''})">Add New</button>
    </div>

    <table class="table table-responsive-sm table-bordered border-0">
      <caption class="d-none"></caption>
      <thead>
        <tr>
          <th scope="col">S/N</th>
          <th scope="col">Name</th>
          <th scope="col" class="d-none">Actions</th>
        </tr>
      </thead>
      <tbody>
       <tr v-for="(permission,index) in permissions" :key="permission.id">
         <td>{{ index + 1 }}</td>
         <td>{{ permission.name }}</td>
          <td class="d-none">
           <div class="d-flex">
             <span class="bg--primary text-white py-1 px-2 mr-2 pointer" @click="$emit('showPermissionForm', {type: 'edit', permission})">Edit</span>
             <span class="bg--secondary py-1 px-2 text-white pointer" @click="showDeletePermissionSwal(permission.id)">Delete</span>
           </div>
         </td>
       </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    permissions: {
      type: Array,
      requored: false,
      default: () => []
    }
  },
  methods: {
    async deletePermission(permissionId) {
      try {
        await this.$repos.userManagementRepo.deletePermission(permissionId);
      }catch(error) {
        console.log(error)
      }
    },
    showDeletePermissionSwal(permissionId) {
      this.$swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.isConfirmed) {
          console.log('here')
          this.deletePermission(permissionId).then(() => {
              this.$emit('updatePermissions')
              this.$swal.fire(
                'Deleted!',
                'Permission deleted.',
                'success'
              )
          })
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal.fire(
            'Cancelled',
            'Delete Cancelled :)',
            'error'
          )
        }
      })
    }
  }
}
</script>