<template>
  <div class="modal fade" id="rolesModal" tabindex="-1" aria-labelledby="rolesLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content rounded-0">
				<div class="modal-header">
					<h5 class="modal-title text-capitalize" id="rolesLabel">{{ title }} Role</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
				</div>
				<div class="modal-body">
          <slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Create'
    }
  }
}
</script>