<template>
  <table
    class="table table-sm-responsive table-bordered border-0"
    id="channelReportsTable"
  >
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">Channel</th>
        <th scope="col">Channel Name</th>
        <th scope="col">Amount</th>
        <th scope="col">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ data.channel }}</td>
        <td>{{ data.channel_name }}</td>
        <td>{{ new Intl.NumberFormat("en-GB").format(data.amount) }}</td>
        <td>
          <button
            :disabled="isDownloading"
            class="btn btn--primary btn-block"
            @click="downloadChannel(data.channel)"
          >
            Download
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    tableData: {
      default: () => [],
      required: false,
    },
  },

  data() {
    return {
      isDownloading: false,
    };
  },

  methods: {
    async downloadChannel(channel) {
      this.isDownloading = true;
      try {
        const firstDay = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        );
        const res = await this.$repos.reports.downloadReportChannel({
          channel: channel.toUpperCase(),
          startDate: moment(firstDay).format("DD-MM-YYYY"),
          endDate: moment().format("DD-MM-YYYY"),
        });
        // Create a blob from the response data
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${channel}.xlsx`;
        document.body.appendChild(a);
        a.click();
        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(url);

      } catch (error) {
        console.log("error", error);
        this.showErrorNotification("Error downloading channel");
      } finally {
        this.isDownloading = false;
      }
    },
  },
};
</script>
