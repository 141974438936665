<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Pay Direct Notification Trace</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <h4 class="card-header border-0 bg-transparent pl-0 mb-2 fs-20">Payments</h4>
              <payments-table :table-data="traceData.payment" />
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <h4 class="card-header border-0 bg-transparent pl-0 mb-2 fs-20">CBS Pay Direct</h4>
              <cbs-paydirect-table :table-data="cbsPaydirects" />
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <h4 class="card-header border-0 bg-transparent pl-0 mb-2 fs-20">CBS POA</h4>
               <cbs-poa-table :table-data="traceData.poa" />
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <h4 class="card-header border-0 bg-transparent pl-0 mb-2 fs-20">Settlement</h4>
               <bill-settlement-table :table-data="traceData.settlement" />
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <h4 class="card-header border-0 bg-transparent pl-0 mb-2 fs-20">Pre Service Bills</h4>
               <bill-settlement-table :table-data="traceData.pre_service_bill" />
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import PaymentsTable from "@/components/tables/flowTables/PaymentsTable";
import CbsPaydirectTable from '@/components/tables/flowTables/CBSPaydirectTable'
import CbsPoaTable from '../../components/tables/flowTables/CBSPoaTable.vue';
import BillSettlementTable from '@/components/tables/flowTables/BillSettlementTable'
import PreServiceBillsTable from '@/components/tables/flowTables/PreServiceBillsTable'
export default {
  props: ['id'],
  components: {
    FilterTable,
    PaymentsTable,
    CbsPaydirectTable,
    CbsPoaTable,
    BillSettlementTable,
    PreServiceBillsTable
  },
  data() {
    return {
      cbsQueueData : [],
      isLoadingPage: true,
      traceData: {},
    }
  },
  methods: {
    async getCbsQueueData() {
      this.isLoadingPage = true
      try {
        const { data } = await this.$repos.payDirectNotificationRepo.getTraceNotification(this.id)
        this.traceData = data
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    }
  },
  async mounted() {
    await this.getCbsQueueData(this.queryStrings, true)
  },
};
</script>