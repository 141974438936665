<template>
  <table class="table table-sm-responsive table-bordered border-0" id="bankReportsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">S/N</th>
        <th scope="col">Bank Name</th>
        <th scope="col">Bank Code</th>
        <th scope="col">Collection</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ index + 1 }}</td>
        <td><router-link :to="{ path: `bank-report-details/${data.bank_code}`}">{{ data.bank_name }}</router-link></td>
        <td>{{ data.bank_code }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.amount) }}</td>
      </tr>
       <tr>
        <td colspan="3" class="text-right"><span class="text--secondary font-weight-bold text-right">Total</span></td>
        <td><span class="text--secondary font-weight-bold">{{ tableData | calculateTotalAmount }}</span></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      default: () => [],
      required: false
    },
  },
  filters: {
    calculateTotalAmount: function (data) {
      if (!data) return ''
      return data.reduce((previousAmount, currentAmount, index)=> {
        return previousAmount + +(currentAmount.amount)}, 0);
    }
  }
}
</script>