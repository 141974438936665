<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section >
        <div class="section--title">
          <h1>CBS Pay Direct</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <FilterTable
          :fromDate="queryStrings.from_date"
          :toDate="queryStrings.to_date"
          :searchStr="queryStrings.search_str"
          v-on:update:fromDate="queryStrings.from_date = $event"
          v-on:update:toDate="queryStrings.to_date = $event"
          v-on:update:searchStr="queryStrings.search_str = $event"
          @flterTable="updateCbsPayDirectData(queryStrings, 'filter')"
        />
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Showing result for record date from {{ queryStrings.from_date |  formatDate}} to
                    <span v-if="queryStrings.to_date">
                      {{ queryStrings.to_date |  formatDate}}
                    </span>
                    <span v-else>
                      {{ new Date() |  formatDate}}
                    </span>
                  </p>
                </div>

                <export-actions
                  :table-data="cbsPaydirects"
                  filename="cbs-paydirect.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light" v-simple-print="'cbdPayDirect'"
                  >Print</button>
                </export-actions>
              </div>
              <cbs-paydirect-table :table-data="cbsPaydirects" />
              <el-pagination
                background
                layout="prev, pager, next"
                :total="paginationData.total"
                :current-page.sync="paginationData.page"
                :page-size.sync="paginationData.limit"
                :hide-on-single-page="true"
                :pager-count="5"
                @current-change="clickCallback"
                @prev-click="prevPage"
                @next-click="nextPage"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="cbs paydirect"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <cbs-paydirect-table
              :table-data="cbsPaydirects"
            />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from '@/components/shared/FilterTable'
import CbsPaydirectTable from '@/components/tables/flowTables/CBSPaydirectTable'
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    FilterTable,
    CbsPaydirectTable,
    VueHtml2pdf
  },
  data() {
    return {
      isLoadingPage: true,
      cbsPaydirects: [],
      paginationData: {},
      queryStrings: {
        from_date: (new Date()).toISOString(),
        per_page: 50,
        search_str: '',
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getCbsPayDirectData(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.CBSPaydirectRepo.getCbsPayDirectData(qs);
        const paginationData = {
          total: data.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page
        }
        this.paginationData = {...paginationData}
        this.cbsPaydirects = data.data
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    updateCbsPayDirectData(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = this.queryStrings
      this.serializeQs(qs)
      this.getCbsPayDirectData(qs, false)
    },
    clickCallback(page) {
      this.updateCbsPayDirectData({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updateCbsPayDirectData({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updateCbsPayDirectData({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
  },
  computed: {
    jsonFields() {
      return {
        Processed: "processed",
        "Payment Lod ID": "payment_log_id",
        "Customer Ref": "raw_request",
        "Customer Name": "customer_name",
        "Amount": "amount",
        "Bank": "bank",
        "Allocation Code": "allocation_code",
        "Revenue Allocation": "revenue_allocation",
        "Agency ID": "agency_id",
        "RIN": "rin"
      }
    }
  },
  async mounted() {
    await this.getCbsPayDirectData(this.queryStrings, true)
  },
}
</script>