<template>
  <section class="d-flex mb-3">
    <button class="ml-2 btn btn-sm btn-light">Copy</button>
    <download-excel
      class="ml-2 btn btn-sm btn-light"
      :data="tableData"
      :fields="jsonFields"
      type="csv"
      :name="filename"
    >
      CSV
    </download-excel>
    <download-excel
      class="ml-2 btn btn-sm btn-light"
      :data="tableData"
      :fields="jsonFields"
      type="xls"
      :name="filename"
    >
      Excel
    </download-excel>
    <button class="ml-2 btn btn-sm btn-light" @click="$emit('generatePDF')">PDF</button>
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: false,
      default: () => []
    },
    filename: {
      type: String,
      required: false,
      default: () => ''
    },
    jsonFields: {
      type: Object,
      required: false,
      dafault: {}
    }
  },
}
</script>