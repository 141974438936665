<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>CBS Pay On Account</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <FilterTable
          :from-date="queryStrings.from_date"
          :to-date="queryStrings.to_date"
          :searchStr="queryStrings.search_str"
          v-on:update:fromDate="queryStrings.from_date = $event"
          v-on:update:toDate="queryStrings.to_date = $event"
          v-on:update:searchStr="queryStrings.search_str = $event"
          @flterTable="updateCbsPoa(queryStrings, 'filter')"
        />
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Showing result for record date from {{ queryStrings.from_date |  formatDate}} to
                    <span v-if="queryStrings.to_date">
                      {{ queryStrings.to_date |  formatDate}}
                    </span>
                    <span v-else>
                      {{ new Date() |  formatDate}}
                    </span>
                  </p>
                </div>

                <export-actions
                  :table-data="cbsPoa"
                  filename="cbsPoa.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light" v-simple-print="'cbsPoaTable'"
                  >Print</button>
                </export-actions>
              </div>
              <cbs-poa-table :table-data="cbsPoa" />
              <el-pagination
                background
                layout="prev, pager, next"
                :total="paginationData.total"
                :current-page.sync="paginationData.page"
                :page-size.sync="paginationData.limit"
                :hide-on-single-page="true"
                :pager-count="5"
                @current-change="clickCallback"
                @prev-click="prevPage"
                @next-click="nextPage"
              >
              </el-pagination>
              <!-- export as pdf -->
              <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                filename="cbs poa"
                :pdf-quality="2"
                :manual-pagination="false"
                :pdf-content-width="'100%'"
                pdf-format="a4"
                pdf-orientation="landscape"
                ref="html2Pdf"
              >
                <div slot="pdf-content">
                  <cbs-poa-table :table-data="cbsPoa" />
                </div>
              </vue-html2pdf>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import CbsPoaTable from '../../components/tables/flowTables/CBSPoaTable.vue';
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    FilterTable,
    CbsPoaTable,
    VueHtml2pdf
  },
  data() {
    return {
      isLoadingPage: true,
      cbsPoa: [],
      paginationData: {},
      queryStrings: {
        from_date: (new Date()).toISOString(),
        search_str: '',
        per_page: 50,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getCbsPoaData(qs, showLoadingIndicator) {
       if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.cbsPoaRepo.getCbsPoaData(qs);
        const paginationData = {
          total: data.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page
        }
        this.paginationData = {...paginationData}
        this.cbsPoa = data.data
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    clickCallback(page) {
      this.updateCbsPoa({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updateCbsPoa({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updateCbsPoa({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    updateCbsPoa(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = this.queryStrings
      this.serializeQs(qs)
      this.getCbsPoaData(qs, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        'Updated RDM': "updated_rdm",
        'Payment Log ID': "payment_id",
        'Tax Payer Name': 'tax_payer_name',
        'Contact Address': "contact_address",
        'Mobile Number': 'mobile_number',
        'Email Address': 'email_address',
        'Economic Activities Id': 'economic_activities_id',
        'Tax Office Id': 'tax_office_id',
        'Notification Method Id': 'notification_method_id',
        'Revenue Stream Id': "revenue_stream_id",
        'Transaction Ref No': 'transaction_ref_no',
        'Amount': 'amount',
        'Payment Date': 'payment_date'
      }
    }
  },
  async mounted() {
    await this.getCbsPoaData(this.queryStrings, true)
  },
};
</script>