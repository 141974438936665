<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Unprocessed Pay Direct Notifications</h1>
          <hr />
        </div>
      </section>
      <section class="mt-5">
        <FilterTable
          :toDate="queryStrings.to_date"
          :fromDate="queryStrings.from_date"
          :searchStr="queryStrings.search_str"
          v-on:update:fromDate="queryStrings.from_date = $event"
          v-on:update:toDate="queryStrings.to_date = $event"
          v-on:update:searchStr="queryStrings.search_str = $event"
          @flterTable="updatePaydirecNotifications(queryStrings, 'filter')"
        />
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="card border-0 w-100">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Showing result for record date from {{ queryStrings.from_date |  formatDate}} to
                    <span v-if="queryStrings.to_date">
                      {{ queryStrings.to_date |  formatDate}}
                    </span>
                    <span v-else>
                      {{ new Date() |  formatDate}}
                    </span>
                  </p>
                </div>
                <export-actions
                  :table-data="payDirectNotifications"
                  filename="paydirect-notifications.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light" v-simple-print="'payDirectNotificationTable'"
                  >Print</button>
                </export-actions>
              </div>

              <pay-direct-notification-table :table-data="payDirectNotifications" />

              <el-pagination
                background
                layout="prev, pager, next"
                :total="paginationData.total"
                :current-page.sync="paginationData.page"
                :page-size.sync="paginationData.limit"
                :hide-on-single-page="true"
                :pager-count="5"
                @current-change="clickCallback"
                @prev-click="prevPage"
                @next-click="nextPage"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="pay direct notifications"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <pay-direct-notification-table
              :table-data="payDirectNotifications"
            />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import PayDirectNotificationTable from "@/components/tables/flowTables/PayDirectNotificationsTable";
import VueHtml2pdf from 'vue-html2pdf'
export default {
  components: {
    FilterTable,
    PayDirectNotificationTable,
    VueHtml2pdf
  },
  data() {
    return {
      payDirectNotifications: [],
      paginationData: {},
      isLoadingPage: true,
      output: null,
      queryStrings: {
        from_date: '1970-01-01',
        per_page: 50,
        search_str: '',
        processed: 0,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getPaydirecNotifications(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.payDirectNotificationRepo.getAll(qs)
        this.payDirectNotifications = data.data
        const paginationData = {
          total: data.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page
        }
        this.paginationData = {...paginationData}
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    clickCallback(page) {
      this.updatePaydirecNotifications({page})
    },
    prevPage() {
      const page = this.paginationData.page
      this.updatePaydirecNotifications({page: page - 1})
    },
    nextPage() {
      const page = this.paginationData.page
      this.updatePaydirecNotifications({page: page - 1})
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    updatePaydirecNotifications(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = this.queryStrings
      this.serializeQs(qs)
      this.getPaydirecNotifications(qs, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        Processed: "processed",
        "Payment Log ID": "payment_log_id",
        "Raw Request": "raw_request",
        "Raw Response": "raw_response"
      }
    }
  },
  async mounted() {
    this.getPaydirecNotifications(this.queryStrings, true)
  },
};
</script>