<template>
  <div class="mw-100">
    <table class="table table-bordered border-0" id="webPayNotificationsTable">
      <caption class="d-none"></caption>
      <thead>
        <tr>
          <th scope="col">S/N</th>
          <th scope="col">Bill Item Id</th>
          <th scope="col">Bill Item Name</th>
          <th scope="col">Bill Item Ref</th>
          <th scope="col">Amount (&#8358;)</th>
          <th scope="col">Tax Amount (&#8358;)</th>
          <th scope="col">Created At</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment, index) in tableData" :key="payment.id">
          <td>{{ index + 1 }}</td>
          <td>{{ payment.bill_item_id }}</td>
          <td><h6><span class="badge badge-secondary">{{ payment.bill_item_name }}</span></h6></td>
          <td>{{ payment.bill_item_ref }}</td>
          <td>{{ new Intl.NumberFormat('en-GB').format(payment.amount) }}</td>
          <td>{{ new Intl.NumberFormat('en-GB').format(payment.tax_amount) }}</td>
          <td>{{ payment.created_at | formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{
    }
  },
  methods: {
  }
}
</script>