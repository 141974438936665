<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Reversed Collections Report</h1>
          <hr />
        </div>
      </section>
      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="row mb-3" @submit.prevent="updateReversedReports(queryStrings, 'filter')">
              <div class="col-md-5">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Year</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="years"
                      v-model="year"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label">Month</label>
                  <div class="col-sm-10">
                    <v-select
                      :options="months"
                      label="label"
                      v-model="month"
                      :reduce="month => month.value"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <button class="btn btn--primary btn-block">Filter</button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section class="mt-5">
        <div class="row">
          <div class="card border-0">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Reports
                  </p>
                </div>
                <export-actions
                  :table-data="reversedReports"
                  filename="Reversed_Reports.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light" v-simple-print="'reversedReportsTable'"
                  >Print</button>
                </export-actions>
              </div>
              <reversed-report-table :table-data="reversedReports" />
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Reversed Reports"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <reversed-report-table :table-data="reversedReports" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import ReversedReportTable from '@/components/tables/reportTables/ReversedReportTable'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueHtml2pdf,
    ReversedReportTable
  },
  data() {
    return {
      months: [
        {
          label: 'January',
          value: '01'
        },
        {
          label: 'Feburary',
          value: '02'
        },
        {
          label: 'March',
          value: '03'
        },
        {
          label: 'April',
          value: '04'
        },
        {
          label: 'May',
          value: '05'
        },
        {
          label: 'June',
          value: '06'
        },
        {
          label: 'July',
          value: '07'
        },
        {
          label: 'August',
          value: '08'
        },
        {
          label: 'September',
          value: '09'
        },
        {
          label: 'October',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'December',
          value: '12'
        },
      ],
      reversedReports: [],
      isLoadingPage: true,
      paginationData: {},
      month: '',
      year: '',
      currentYear: new Date().getFullYear(),
      years: [],
      queryStrings: {
        month: '',
        year: '',
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getReversedReports(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data: {total, year, month, reports} } = await this.$repos.reports.getReversedCollections(qs);
        this.reversedReports = reports
        this.total = total
        this.year = year
        this.month = month

        let startYear = Number(this.currentYear) - 20;
        let endYear = Number(this.currentYear)
        const yearsArray = []

        for (let i = startYear; i <= endYear; i++) {
          yearsArray.push((startYear++).toString())
        }
        this.years = [...new Set(yearsArray)]
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
    updateReversedReports(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = {...this.queryStrings, year: this.year, month: this.month}
      this.serializeQs(qs)
      this.getReversedReports (qs, false)
    }
  },
  computed: {
    jsonFields() {
      return {
        Amount: "amount",
        "Alternate Customer Reference": "alternate_cust_reference",
        "Bank Code": "bank_code",
        "Bank Name": "bank_name",
        "Branch Name": "branch_name",
        "Channel Name": 'channel_name',
        "Customer Phone Number": "customer_phone_number",
        "Deposit Slip Number": "deposit_slip_number",
        "Depositor Name": "deposit_name",
        "Fee Name": 'fee_name',
        "Ftp Password": 'ftp_password',
        "Ftp url": 'ftp_url',
        "Ftp username": 'ftp_username',
        "Id": 'id',
        "Institution Id": 'insitution_id',
        "Institution Name": 'insitution_name',
        "Location": 'location',
        "Original Payment Log Id": 'original_payment_log_id',
        "Original Payment Reference": 'original_payment_reference',
        "Other Customer Info": 'other_customer_info',
        "Payment Status": 'payment_Status',
        "Payment Currency": 'payment_currency',
        "Payment Date": 'payment_date',
        "Payment Log Id": 'payment_log_id',
        "Payment Method": 'payment_method',
      }
    }
  },
  async mounted() {
    await this.getReversedReports(this.queryStrings, true)
  },
}
</script>