<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Web pay notifications</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <FilterTable
          :from-date="queryStrings.from_date"
          :to-date="queryStrings.to_date"
          :searchStr="queryStrings.search_str"
          :enableDownload=true
          v-on:update:fromDate="queryStrings.from_date = $event"
          v-on:update:toDate="queryStrings.to_date = $event"
          v-on:update:searchStr="queryStrings.search_str = $event"
          @flterTable="updateWebPayPayments(queryStrings, 'filter')"
          @download="downlodPage()"
        />
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="card border-0 col-12">
            <div class="card-body">
              <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                <div class="w-100">
                  <p class="text--secondary font-weight-bold mb-3">
                    Showing result for record date from
                    {{ queryStrings.from_date | formatDate }} to
                    <span v-if="queryStrings.to_date">
                      {{ queryStrings.to_date | formatDate }}
                    </span>
                    <span v-else>
                      {{ new Date() | formatDate }}
                    </span>
                  </p>
                </div>

                <export-actions
                  :table-data="webPayNotifications"
                  filename="webpayNotifications.xls"
                  :json-fields="jsonFields"
                  @generatePDF="generatePDF"
                >
                  <button
                    class="ml-2 btn btn-sm btn-light"
                    v-simple-print="'webPayNotificationsTable'"
                  >
                    Print
                  </button>
                </export-actions>
              </div>

              <web-pay-notifications-table :table-data="webPayNotifications" />

              <el-pagination
                background
                layout="prev, pager, next"
                :total="paginationData.total"
                :current-page.sync="paginationData.page"
                :page-size.sync="paginationData.limit"
                :hide-on-single-page="true"
                :pager-count="5"
                @current-change="clickCallback"
                @prev-click="prevPage"
                @next-click="nextPage"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="bill settlement"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <web-pay-notifications-table :table-data="webPayNotifications" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import FilterTable from "@/components/shared/FilterTable";
import WebPayNotificationsTable from "@/components/tables/flowTables/WebPayNotificationsTable";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    FilterTable,
    WebPayNotificationsTable,
    VueHtml2pdf,
  },
  data() {
    return {
      isLoadingPage: true,
      paginationData: {},
      webPayNotifications: [],
      queryStrings: {
        from_date: new Date().toISOString(),
        search_str: "",
        per_page: 50,
        page: 1,
      },
    };
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach((elem) => {
        if (qs[elem] === null || qs[elem] === "") {
          delete qs[elem];
        }
      });
    },
    async getWebPayPayments(qs, showLoadingIndicator) {
      if (showLoadingIndicator) {
        this.isLoadingPage = true;
      }
      try {
        this.serializeQs(qs);
        const { data } = await this.$repos.webPay.getWebPayNotifications(qs);
        const paginationData = {
          total: data.total,
          currentPage: this.queryStrings.page,
          limit: this.queryStrings.per_page,
          page: this.queryStrings.page,
        };
        this.paginationData = { ...paginationData };
        this.webPayNotifications = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingPage = false;
      }
    },
    clickCallback(page) {
      this.updateWebPayPayments({ page });
    },
    prevPage() {
      const page = this.paginationData.page;
      this.updateWebPayPayments({ page: page - 1 });
    },
    nextPage() {
      const page = this.paginationData.page;
      this.updateWebPayPayments({ page: page - 1 });
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    updateWebPayPayments(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0];
      const qsValue = Object.values(qsObj)[0];
      this.queryStrings[qsKey] = qsValue;
      if (type === "filter") {
        this.queryStrings.page = 1;
      }
      const qs = this.queryStrings;
      this.serializeQs(qs);
      this.getWebPayPayments(qs, false);
    },
    async downlodPage(){
      /* this.$repos.webPay.downloadWebpayments(
        `/web_pay/reports?start_date=${this.queryStrings.from_date}=&end_date=${this.queryStrings.to_date}`
      ) */
      const link = document.createElement('a');
      const url = 'https://tyson.eirs.gov.ng/tyson/api/web_pay/reports?start_date='+this.queryStrings.from_date+'&end_date='+this.queryStrings.to_date
      /* console.log('url', url)
      link.href = url
      link.setAttribute('download', 'report.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click(); */
      window.open(url, '_blank')
    }
  },
  computed: {
    jsonFields() {
      return {
        Amount: "amount",
        "Tax Payer ID": "taxpayer_id",
        "Customer Name": "cust_name",
        "Bill Id": "bill_id",
        "Transaction Ref No": "txn_ref",
      };
    },
  },
  async mounted() {
    await this.getWebPayPayments(this.queryStrings, true);
  },
};
</script>