<template>
  <table class="table table-sm-responsive table-bordered border-0" id="generalReportsTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">RIN</th>
        <th scope="col">Tax Payer Name</th>
        <th scope="col">Payment Date</th>
        <th scope="col">Bank of Payment</th>
        <th scope="col">Revenue Stream</th>
        <th scope="col">Allocation Code</th>
        <th scope="col" colspan="2">Notes</th>
        <th scope="col">Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData" :key="index">
        <td>{{ data.tax_payer_rin }}</td>
        <td>{{ data.tax_payer_name }}</td>
        <td>{{ data.payment_date | formatDate }}</td>
        <td></td>
        <td>{{ data.revenue_stream.Revenue_Stream_Name }}</td>
        <td>{{ data.revenue_stream.Allocation_Code }}</td>
        <td colspan="2">{{ data.notes }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(data.amount) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
 props: {
    tableData: {
      default: () => [],
      required: false
    },
  }
}
</script>