
export default (axios) => ({
	getAll(queryString) {
		return axios.get("/paydirect_notifications", {
			params: queryString
		})
	},
	getTraceNotification(id) {
		return axios.get(`/paydirect_notifications/${id}/trace`)
	}
})