<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Permissions</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
                <permissions-table
                  :permissions="permissions"
                  @updatePermissions="getpermissions"
                  @showPermissionForm="showPermissionForm"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <PermissionModal :title="modalTitle">
      <PermissionForm
        :permission="permission"
        v-on:update:permission="permission.name = $event"
        @submit="submit"
        :isSubmitting="isSubmitting"
      />
    </PermissionModal>
  </div>
</template>

<script>
import PermissionsTable from '@/components/tables/userManagement/PermissionsTable'
import PermissionModal from '@/components/modals/users/PermissionModal'
import PermissionForm from '@/components/forms/users/PermissionForm'
export default {
  components: {
    PermissionsTable,
    PermissionModal,
    PermissionForm
  },
  data() {
    return {
      permissions: [],
      isLoadingPage: true,
      modalTitle: 'Create',
      permission: {},
      isSubmitting: true,
    }
  },
  methods: {
    async getpermissions(showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        const { data: permissions } = await this.$repos.userManagementRepo.getPermissions();
        this.permissions = permissions
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    showPermissionForm(permission) {
      this.modalTitle = permission.type
      this.permission = {...permission.permission }
      console.log(this.permission);
      $('#permissionModal').modal('show')
    },
    async createPermission() {
      this.isSubmitting = true
      try {
       await this.$repos.userManagementRepo.createPermission({name: this.permission.name, guard_name: 'api'});
       this.showSuccessNotification('Permission created successfully')
       $('#permissionModal').modal('hide')
       this.getpermissions()
      }catch(error) {
        this.showErrorLoginNotification(error.response.data.message)
      }finally {
        this.isSubmitting = false
      }
    },
    async updatePermission() {
      try {
        this.isSubmitting = true
       const {data} = await this.$repos.userManagementRepo.updatePermission({name: this.permission.name, guard_name: 'api', id: this.permission.id});
       this.showSuccessNotification(data.message)
       $('#permissionModal').modal('hide')
       this.getpermissions(false)
      }catch(error) {
        this.showErrorLoginNotification(error.response.message)
      }finally {
        this.isSubmitting = false
      }
    },
    async submit() {
      if(this.modalTitle === 'create') {
        await this.createPermission()
      }else {
        await this.updatePermission()
      }
    }
  },
  async mounted() {
    await this.getpermissions(true)
  }
}
</script>