<template>
	<div class="modal fade" id="settlementModal" tabindex="-1" aria-labelledby="settlementLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content rounded-0">
				<div class="modal-header">
					<h5 class="modal-title" id="settlementLabel">Payment Items ({{ payItemId }})</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
          </button>
				</div>
				<div class="modal-body rounded-none">
					<div class="accordion" id="paymentItemAccordion" v-if="paymentItems.length > 0">
						<div class="card mb-2" v-for="item in paymentItems" :key="item.id">
							<div class="card-header bg--primary text-white p-0" id="headingOne">
								<h2
									:data-target='`#collapse${item.id}`'
									class="mb-0 text-left pointer fs-20 w-100 p-3"
									style="cursor: pointer;"
									data-toggle="collapse"
									ref="customControlAccordion"
									aria-expanded="true"
									:aria-controls="`collapse${item.id}`"
								>
									{{ item.bill_item_ref }}
								</h2>
							</div>

							<div
                :id="`collapse${item.id}`"
								class="collapse"
								aria-labelledby="headingOne"
								data-parent="#paymentItemAccordion"
							>
								<ul class="list-group list-group-flush">
									<li class="list-group-item d-flex justify-content-between">
                    <div class="text-muted">Amount</div>
                    <div>{{ new Intl.NumberFormat('en-GB').format(item.amount) }}</div>
									</li>
                  <li class="list-group-item d-flex justify-content-between">
                    <div class="text-muted">Tax amount</div>
                    <div>{{ new Intl.NumberFormat('en-GB').format(item.tax_amount) }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between">
                    <div class="text-muted">bill Item Id</div>
                    <div>{{ item.bill_item_id }}</div>
									</li>
									<li class="list-group-item d-flex justify-content-between flex-column">
                    <div class="text-muted">Bill Item Name</div>
                    <div>{{ item.bill_item_name }}</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
          <div v-else class="py-5 text-center">
            <h4>No payment items</h4>
          </div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		paymentItems : {
			type: Array,
			default: () => [],
			required: false
		},
		payItemId: {
			type: String,
			default: () => "",
			required: false
		}
	},
	methods: {
		toggleFirstAccordion() {
			const accordions = this.$refs.customControlAccordion
      accordions && accordions.length > 0 && accordions[0].click()
		}
	},
	updated() {
		this.$nextTick(function () {
			this.toggleFirstAccordion()
		})
	}
}
</script>