<template>
  <div>
    <div class="d-flex border-bottom mb-4 px-2 align-items-center justify-content-between">
      <div class="mb-3">
        <p class="text--secondary font-weight-bold mb-0">
          List
        </p>
      </div>
      <button class="btn btn--secondary mb-3" @click="$router.push({name: 'CreateUser'})">Add New</button>
    </div>

    <table class="table table-responsive-sm table-bordered border-0">
      <caption class="d-none"></caption>
      <thead>
        <tr>
          <th scope="col">S/N</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Roles</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
       <tr v-for="(user, index) in users" :key="user.id">
         <td>{{ index + 1 }}</td>
         <td>{{ user.name }}</td>
         <td>{{ user.email }}</td>
         <td>
            <template v-for="(role, index) in user.roles">
              <span class="badge badge-info p-1 rounded-0 mx-1" :key="index">{{ role.name }}</span>
            </template>
          </td>
         <td>
           <div class="d-flex">
             <span class="bg--primary text-white py-1 px-2 mr-2 pointer" @click="$emit('showEditUserForm', user)">Edit</span>
             <span class="bg--secondary py-1 px-2 text-white pointer" @click="showDeleteUserSwal(user.id)">Delete</span>
           </div>
         </td>
       </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      requored: false,
      default: () => []
    }
  },
  methods: {
     async deleteUser(userId) {
      try {
        await this.$repos.userManagementRepo.deleteUser(userId);
      }catch(error) {
        console.log(error)
      }
    },
    showDeleteUserSwal(userId) {
      this.$swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.isConfirmed) {
          console.log('here')
          this.deleteUser(userId).then(() => {
              this.$emit('updateUsers')
              this.$swal.fire(
                'Deleted!',
                'User deleted.',
                'success'
              )
          })
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal.fire(
            'Cancelled',
            'Delete Cancelled :)',
            'error'
          )
        }
      })
    }
  },
}
</script>