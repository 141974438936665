
export default (axios) => ({
  getMonthlyReports(queryStrings) {
    return axios.get("/reports/monthly-collections", {
      params: queryStrings
    })
  },
  getReversedCollections(queryStrings) {
    return axios.get("/reports/reversed-collections", {
      params: queryStrings
    })
  },
  getYearlyCollections(queryStrings) {
    return axios.get("/reports/yearly-collections", {
      params: queryStrings
    })
  },
  getRevenueBasedCollections(queryStrings) {
    return axios.get("/reports/revenue-base-collections", {
      params: queryStrings
    })
  },
  getGeneralCollections(queryStrings) {
    return axios.get("/reports/general-collections", {
      params: queryStrings
    })
  },
  getChannelCollections(queryStrings) {
    return axios.get("/reports/channel-base-collections", {
      params: queryStrings
    })
  },
  getBankCollections(queryStrings) {
    return axios.get("/reports/bank-base-collections", {
      params: queryStrings
    })
  },
  getBankDetailsCollection(queryStrings) {
    return axios.get("/reports/bank-details-collections", {
      params: queryStrings
    })
  },
  getBankList() {
    return axios.get("/reports/list-banks")
  },
  downloadReportChannel({channel, startDate, endDate}) {
    return axios.get(`/reports/channel-base-collections/download?channel=${channel}&start_date=${startDate}&end_date=${endDate}`)
  },
})