<template>
  <div>
    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page data -->
    <template v-if="!isLoadingPage">
      <section>
        <div class="section--title">
          <h1>Monthly Collections Report</h1>
          <hr />
        </div>
      </section>

      <section class="mt-5">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="table--caption">
                  <h3>Report Filter</h3>
                  <hr />
                </div>
              </div>
            </div>
            <form class="row mb-3" @submit.prevent="updateMonthlyReports(queryStrings, 'filter')">
              <div class="col-md-8">
                <div class="form-group row">
                  <label class="col-sm-1 col-form-label">Year</label>
                  <div class="col-sm-7">
                    <v-select
                      :options="years"
                      v-model="year"
                    ></v-select>
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn--primary btn-block">Filter</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section class="mt-5">
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div class="card-body">
                <div class="d-flex border-bottom mb-4 px-2 align-items-center">
                  <div class="w-100">
                    <p class="text--secondary font-weight-bold mb-3">Report Result</p>
                  </div>
                  <export-actions
                    :table-data="monthlyReports"
                    filename="monthly-reports.xls"
                    :json-fields="jsonFields"
                    @generatePDF="generatePDF"
                  >
                    <button
                      class="ml-2 btn btn-sm btn-light" v-simple-print="'monthlyReportTable'"
                    >Print</button>
                  </export-actions>
                </div>
                <monthly-report-table
                  :table-data="monthlyReports" :totalReports="totalMontylyReports"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- export as pdf -->
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="monthly reports"
          :pdf-quality="2"
          :manual-pagination="false"
          :pdf-content-width="'100%'"
          pdf-format="a4"
          pdf-orientation="landscape"
          ref="html2Pdf"
        >
          <div slot="pdf-content">
            <monthly-report-table :table-data="monthlyReports" :totalReports="totalMontylyReports" />
          </div>
        </vue-html2pdf>
      </section>
    </template>
  </div>
</template>

<script>
import MonthlyReportTable from '@/components/tables/reportTables/MonthlyReportTable'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    MonthlyReportTable,
    VueHtml2pdf,
  },
  data() {
    return {
      isLoadingPage: true,
      monthlyReports: [],
      totalMontylyReports: {},
      years: [],
      year: null,
      currentYear: new Date().getFullYear(),
      queryStrings: {
        per_page: 50,
        page: 1
      }
    }
  },
  methods: {
    serializeQs(qs) {
      Object.keys(qs).forEach(elem => {
        if(qs[elem]===null || qs[elem]==='') {
          delete qs[elem];
        }
      });
    },
    async getMonthlyReports(qs, showLoadingIndicator) {
      if(showLoadingIndicator) {
        this.isLoadingPage = true
      }
      try {
        this.serializeQs(qs);
        const { data: {reports, total, year} } = await this.$repos.reports.getMonthlyReports(qs);
        this.monthlyReports = []
        for (const property in reports) {
          this.monthlyReports.push(reports[property])
        }
        this.totalMontylyReports = {...total}
        let startYear = Number(this.currentYear) - 20;
        let endYear = Number(this.currentYear)

        const yearsArray = []
        for (let i = startYear; i <= endYear; i++) {
          yearsArray.push((startYear++).toString())
        }
        this.years = [...new Set(yearsArray)]
        this.year = year
      }catch(error) {
        console.log(error)
      }finally {
        this.isLoadingPage = false
      }
    },
    updateMonthlyReports(qsObj, type) {
      const qsKey = Object.keys(qsObj)[0]
      const qsValue = Object.values(qsObj)[0]
      this.queryStrings[qsKey] = qsValue
      if(type === 'filter') {
        this.queryStrings.page = 1
      }
      const qs = {...this.queryStrings, year: this.year}
      this.serializeQs(qs)
      this.getMonthlyReports(qs, false)
    },
    generatePDF () {
      this.$refs.html2Pdf.generatePdf()
    },
  },
  computed: {
    jsonFields() {
      return {
        Month: "month",
        "Notifications": "notifications",
        "POA": "poa",
        "Queue": "queue",
        "Settlement": "settlement",
        "Manual POA": "manual_poa",
      }
    }
  },
  async mounted() {
    await this.getMonthlyReports({...this.queryStrings, year: this.year}, true)
  },
}
</script>