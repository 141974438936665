<template>
  <div>
    <!-- page title -->
    <section>
      <div class="section--title">
        <h1>Today's Stats</h1>
        <hr />
      </div>
    </section>

    <!-- loading page indicator -->
    <template v-if="isLoadingPage">
      <pre-loader />
    </template>

    <!-- page content -->
    <section class="mt-5" v-if="!isLoadingPage">
      <div class="row">
        <div class="col-md-4">
          <div class="card shadow-none border-0 analytics--card">
            <div class="analytics--card__title">
              <h6>Total POA</h6>
            </div>
            <hr />
            <div class="analytics--card__amount">
              <h6>₦ {{ new Intl.NumberFormat('en-GB').format(analytics.total_pay_on_account) }}</h6>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card shadow-none border-0 analytics--card">
            <div class="analytics--card__title">
              <h6>Total Bill Settlement</h6>
            </div>
            <hr />
            <div class="analytics--card__amount">
              <h6>₦ {{ new Intl.NumberFormat('en-GB').format(analytics.total_settlement) }}</h6>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card shadow-none border-0 analytics--card">
            <div class="analytics--card__title">
              <h6>Total Transactions</h6>
            </div>
            <hr />
            <div class="analytics--card__amount">
              <h6>₦ {{ new Intl.NumberFormat('en-GB').format(parseFloat(analytics.total_settlement) + parseFloat(analytics.total_pay_on_account) ) }}</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mt-5"  v-if="!isLoadingPage">
      <div class="section--title">
        <h1>This Week's Stats</h1>
        <hr />
      </div>
      <div class="row mt-5">
        <div class="col-md-6 h-100 mb-3 mb-md-0">
          <div class="card shadow-none border-0">
            <div class="card-body">
              <div class="table--caption">
                <h3>Paydirect Transactions</h3>
                <hr />
              </div>
              <line-chart :chart-data="datacollection" :options="options"></line-chart>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow-none border-0">
            <div class="card-body">
              <div class="table--caption">
                <h3>This Week Transaction Totals</h3>
                <hr />
              </div>
              <dashboard-weekly-stats :weeklyStats="analytics.this_week_table" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import DashboardWeeklyStats from '@/components/tables/dashboard/DashWeeklyStats';
import LineChart from "@/charts/LineChart.js";
export default {
  components: {
    DashboardWeeklyStats,
    LineChart
  },
  data() {
    return {
      analytics: {},
      isLoadingPage: true,
      datacollection: {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        datasets: [
          {
            label: "Cbs Pay on Account",
            backgroundColor: "#FF6384",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
          {
            label: "Settlement",
            backgroundColor: "#FDF423",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    async getDashboardAnalytics() {
      try {
        const {data} = await this.$repos.analytics.getDashboardAnalytics()
        this.analytics = {...data}
        this.datacollection.datasets[0].data = this.analytics.this_week.cbs_poa.map(item => Number(item.total))
        this.datacollection.datasets[1].data = this.analytics.this_week.settlement.map(item => Number(item.total))
      }catch(error) {
        console.log(error);
      }finally {
        this.isLoadingPage = false
      }
    }
  },
  mounted() {
    this.getDashboardAnalytics()
  },
}
</script>
<style lang="scss">
  .section--title {
    h1 {
      color: $secondary;
      font-weight: bold;
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 15px;
    }
    hr {
      width: 40%;
      display: block;
      border-top: 1px solid $primary;
      margin-left: 0px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }

  .analytics--card {
    padding: 15px;
    &__title {
      h6 {
        color: $secondary;
        font-weight: 700;
        font-size: 16px;
      }
    }
    hr {
      border: 1px solid #fafafa;
    }
    &__amount {
      font-size: 14px;
      font-weight: bold;
      color: $black;
    }
  }

  .table--caption {
    margin-bottom: 30px;
    h3 {
      color: $secondary;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
    }
  }
</style>