export default (axios) => ({
  getFailedPoa(queryStrings) {
    return axios.get("/failed_poa", {
      params: queryStrings
    })
  },
  getFailedSettlements(queryStrings) {
    return axios.get("/failed_settlements", {
      params: queryStrings
    })
  }
})