<template>
  <table class="table table-responsive table-bordered border-0" id="failedPoaTable">
    <caption class="d-none"></caption>
    <thead>
      <tr>
        <th scope="col">Updated RDM</th>
        <th scope="col">Payment Id</th>
        <th scope="col">Tax Payer Type</th>
        <th scope="col">Tax Payer Name</th>
        <th scope="col">Contact Address</th>
        <th scope="col">Mobile Number</th>
        <th scope="col">Email Address</th>
        <th scope="col">Economic Activities ID</th>
        <th scope="col">Tax Office ID</th>
        <th scope="col">Notification Method Id</th>
        <th scope="col">Revenue Stream ID</th>
        <th scope="col">Transaction Ref No</th>
        <th scope="col">Amount</th>
        <th scope="col">Payment Date</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="poa in tableData" :key="poa.id">
        <td><h6><span class="badge badge-secondary">{{ poa.updated_rdm }}</span></h6></td>
        <td>{{ poa.payment_id }}</td>
        <td>{{ poa.tax_payer_type_id }}</td>
        <td>{{ poa.tax_payer_name }}</td>
        <td>{{ poa.contact_address }}</td>
        <td>{{ poa.mobile_number }}</td>
        <td>{{ poa.email_address }}</td>
        <td>{{ poa.economic_activities_id }}</td>
        <td>{{ poa.tax_office_id }}</td>
        <td>{{ poa.notification_method_id }}</td>
        <td>{{ poa.revenue_stream_id }}</td>
        <td>{{ poa.transaction_ref_no }}</td>
        <td>{{ new Intl.NumberFormat('en-GB').format(poa.amount) }}</td>
        <td>{{ poa.payment_date | formatDate }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
  }
}
</script>